import { LoadingLottie } from '.'
import { ctaButtonVariant } from './variants'
import { CTAButtonProps } from './types'
import { useLoadingAnimation } from '@src/hooks/useLoadingAnimation'

/**
 *
 * @param buttonStyle primary | secondary | third | fourth | cta
 * @param buttonSize small(h-40) | medium(h-48) | large(h-56)
 * @param fontSize medium(text-14) | large(text-16)
 * @param disabled 버튼 비활성화 여부 +) buttonStyle: disabled 일 때만 사용
 *
 * @description buttonSize + fontSize 조합에 따라 padding 적용
 * (small + medium: tw-py-10 tw-px-16 | medium + large: tw-p-12 | large + large: tw-py-[15px] tw-px-16
 * )
 *
 * @returns 항공권 디자인에 맞는 CTA 버튼 컴포넌트
 */
export const CTAButton = ({
  buttonStyle,
  buttonSize,
  fontSize,
  buttonClassName,
  children,
  disabled,
  onClick,
  loading,
  type = 'button',
}: CTAButtonProps) => {
  const loadingAnimation = useLoadingAnimation()

  return (
    <button
      type={type}
      onClick={onClick}
      className={ctaButtonVariant({
        buttonStyle,
        buttonSize,
        fontSize,
        class: buttonClassName,
      })}
      disabled={disabled}>
      {loading && loadingAnimation ? (
        <LoadingLottie loadingAnimation={loadingAnimation} />
      ) : (
        children
      )}
    </button>
  )
}
