/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreatePassengerInfoRequest,
  ResponseListDeletePassengerInfoResponseDto,
  ResponseListPassengerInfoDto,
  ResponseResult,
  UpdatePassengerInfoRequest,
} from '../models/index';

export interface CreatePassengerInfoOperationRequest  {
  createPassengerInfoRequest: CreatePassengerInfoRequest;
}

export interface DeletePassengerInfosRequest  {
  seqNos: Array<number>;
}

export interface UpdatePassengerInfoOperationRequest  {
  seqNo: number;
  updatePassengerInfoRequest: UpdatePassengerInfoRequest;
}


/**
 * 
 */
export class PassengerApi extends runtime.BaseAPI {

    /**
     * 탑승객 정보를 등록합니다
     * 탑승객 정보 등록
     */
    async createPassengerInfoRaw(requestParameters: CreatePassengerInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseResult>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/passengers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['createPassengerInfoRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 탑승객 정보를 등록합니다
     * 탑승객 정보 등록
     */
    async createPassengerInfo(requestParameters: CreatePassengerInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseResult> {
        const response = await this.createPassengerInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 탑승객 정보를 삭제합니다
     * 탑승객 정보 삭제
     */
    async deletePassengerInfosRaw(requestParameters: DeletePassengerInfosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListDeletePassengerInfoResponseDto>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/passengers`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 탑승객 정보를 삭제합니다
     * 탑승객 정보 삭제
     */
    async deletePassengerInfos(requestParameters: DeletePassengerInfosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListDeletePassengerInfoResponseDto> {
        const response = await this.deletePassengerInfosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 탑승객 정보를 조회합니다
     * 탑승객 정보 조회
     */
    async getPassengerInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListPassengerInfoDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/passengers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 탑승객 정보를 조회합니다
     * 탑승객 정보 조회
     */
    async getPassengerInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListPassengerInfoDto> {
        const response = await this.getPassengerInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     * 탑승객 정보를 수정합니다
     * 탑승객 정보 수정
     */
    async updatePassengerInfoRaw(requestParameters: UpdatePassengerInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseResult>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/passengers/{seqNo}`.replace(`{${"seqNo"}}`, encodeURIComponent(String(requestParameters['seqNo']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['updatePassengerInfoRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 탑승객 정보를 수정합니다
     * 탑승객 정보 수정
     */
    async updatePassengerInfo(requestParameters: UpdatePassengerInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseResult> {
        const response = await this.updatePassengerInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
