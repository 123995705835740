import { BannerDtoPosition } from '@src/api'
import { match } from 'ts-pattern'

export const getImageDimensions = (position: BannerDtoPosition) => {
  return match(position)
    .with(BannerDtoPosition.TOP, () => ({ width: 327, height: 96 }))
    .with(BannerDtoPosition.RIGHT, () => ({ width: 318, height: 64 }))
    .with(BannerDtoPosition.BOTTOM, () => ({ width: 0, height: 0 })) // 예시로 추가
    .with(BannerDtoPosition.LEFT, () => ({ width: 0, height: 0 })) // 예시로 추가
    .otherwise(() => ({ width: 0, height: 0 }))
}
