import Head from 'next/head'
import { Fragment } from 'react'

export const DefaultHeader = () => {
  return (
    <Fragment>
      <Head>
        <title>socar-flight-app</title>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE-Edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, minimum-scale=1, user-scalable=0"
        />
        <link
          rel="preload"
          href="/fonts/SpoqaHanSansNeoRegular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          fetchPriority="high"
        />
        <link
          rel="preload"
          href="/fonts/SpoqaHanSansNeoMedium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          fetchPriority="high"
        />
        <link
          rel="preload"
          href="/fonts/SpoqaHanSansNeoBold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          fetchPriority="high"
        />
      </Head>
    </Fragment>
  )
}
