import { useState } from 'react'

/**
 * 현재 브라우저의 높이를 기준으로 주어진 percent 만큼의 높이를 반환하는 훅
 * @param percent
 * @returns
 */
export const useSpacingFromTop = (percent: number) => {
  const [spacingFromTop] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.innerHeight * (0.01 * percent)
    }
  })

  return spacingFromTop
}
