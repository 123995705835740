import { ResponseCreateSessionResponse } from '@src/api'
import { createMemberSession } from '@src/views/Login/mutations'

let getSessionRequest: Promise<ResponseCreateSessionResponse> | null

const resetGetOnceRequest = () => {
  getSessionRequest = null
}

export const createSessionOnce = ({
  accessToken,
  refreshToken,
}: {
  accessToken?: string
  refreshToken?: string
  mobile?: string
}) => {
  if (!getSessionRequest) {
    if (accessToken && refreshToken) {
      getSessionRequest = createMemberSession(accessToken, refreshToken, false)
      getSessionRequest.then(resetGetOnceRequest, resetGetOnceRequest)
    }
  }

  return getSessionRequest
}
