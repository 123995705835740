// common.ts
export const IS_SEARCH_MODAL_OPEN_DEFAULT = false
export const SEARCH_TYPE_DEFAULT = 'DEPARTURE'
export const IS_BASIC_FLOW_DEFAULT = true
export const ITINERARY_TYPE_DEFAULT = 'ROUND_TRIP'

// date.ts
export const TRAVEL_DESTINATION_DEFAULT = { code: null, name: null }

// personnel.ts
export const ADULT_COUNT_DEFAULT = 1
export const CHILD_COUNT_DEFAULT = 0
export const INFANT_COUNT_DEFAULT = 0
export const SEAT_CLASS_DEFAULT = [
  {
    code: 'A',
    name: '전체',
    selected: true,
  },
  {
    code: 'Y',
    name: '일반석',
    selected: true,
  },
  {
    code: 'V',
    name: '할인석',
    selected: true,
  },
  {
    code: 'C',
    name: '비즈니스석',
    selected: true,
  },
  {
    code: 'S',
    name: '특가석',
    selected: true,
  },
]

// travelDestination.ts
export const TRAVEL_DATE_DEFAULT = null
