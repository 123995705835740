import { atom } from 'recoil'
import { IS_PASSENGER_EDITABLE } from '../constants/passenger/atomsKey'

/**
 * @description 탑승객 페이지에서 탑승객 편집 가능 여부 (편집 가능한 상태인지 판단하기 위함)
 */
export const isPassengerEditableState = atom<boolean>({
  key: IS_PASSENGER_EDITABLE,
  default: false,
})
