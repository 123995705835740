import {
  APIApi,
  CreateGuestSessionOperationRequest,
  CreateGuestSessionRequest,
  CreateMemberSessionRequest,
  RegisterSelectedFlightToSessionRequest,
  ResponseCreateSessionResponse,
  ResponseResult,
  ResponseString,
  ResponseUnit,
} from '@src/api'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

const api = new APIApi()

export const createGuestSession = (request: CreateGuestSessionRequest) =>
  api.createGuestSession(
    { createGuestSessionRequest: request },
    {
      credentials: 'include',
    }
  )

export const useCreateGuestSessionMutation = (
  options?: UseMutationOptions<ResponseResult, {}, CreateGuestSessionRequest>
) =>
  useMutation({
    mutationFn: createGuestSession,
    ...options,
  })

export const useRegisterScheduleMutation = (
  options?: UseMutationOptions<
    ResponseString,
    {},
    RegisterSelectedFlightToSessionRequest
  >
) =>
  useMutation({
    mutationFn: (data) =>
      api.registerSelectedFlightToSession(data, {
        credentials: 'include',
      }),
    ...options,
  })

export const createMemberSession = (
  accessToken: string,
  refreshToken: string,
  isFromNaver?: boolean
) =>
  api.createMemberSession(
    { isFromNaver },
    {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Refresh-Token': refreshToken,
      },
    }
  )

export const useCreateMemberSessionMutation = (
  isFromNaver?: boolean,
  options?: UseMutationOptions<
    ResponseCreateSessionResponse,
    CreateMemberSessionRequest,
    { accessToken: string; refreshToken: string }
  >
) =>
  useMutation({
    mutationFn: ({ accessToken, refreshToken }) =>
      createMemberSession(accessToken, refreshToken, isFromNaver),
    ...options,
  })

export const useInvalidateSessionMutation = (
  options?: UseMutationOptions<ResponseUnit>
) =>
  useMutation({
    mutationFn: () =>
      api.invalidateSession({
        credentials: 'include',
      }),
    ...options,
  })
