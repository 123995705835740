import {
  ADULT_COUNT,
  CHILD_COUNT,
  INFANT_COUNT,
  SEAT_TYPES,
} from '@src/store/constants/search/atomsKey'
import {
  ADULT_COUNT_DEFAULT,
  CHILD_COUNT_DEFAULT,
  INFANT_COUNT_DEFAULT,
  SEAT_CLASS_DEFAULT,
} from '@src/store/constants/search/defaultValue'
import {
  SELECTED_PERSONNEL_SEAT_TYPE,
  SUM_COUNT,
} from '@src/store/constants/search/selectorKey'
import { storageEffect } from '@src/utils/storageEffect'
import { atom, selector } from 'recoil'

export interface SeatType {
  code: string
  name: string
  selected: boolean
}

export const adultCountState = atom<number>({
  key: ADULT_COUNT,
  default: ADULT_COUNT_DEFAULT,
  effects: [storageEffect('adultCount', 'session')],
})

export const childCountState = atom<number>({
  key: CHILD_COUNT,
  default: CHILD_COUNT_DEFAULT,
  effects: [storageEffect('childCount', 'session')],
})

export const infantCountState = atom<number>({
  key: INFANT_COUNT,
  default: INFANT_COUNT_DEFAULT,
  effects: [storageEffect('infantCount', 'session')],
})

export const seatTypesState = atom<SeatType[]>({
  key: SEAT_TYPES,
  default: SEAT_CLASS_DEFAULT,
  effects: [storageEffect('seatTypes', 'session')],
})

export const sumCountState = selector<number>({
  key: SUM_COUNT,
  get: ({ get }) => {
    const adultCount = get(adultCountState)
    const childCount = get(childCountState)
    const infantCount = get(infantCountState)

    return adultCount + childCount + infantCount
  },
})

export const selectedPersonnelSeatTypeState = selector<string>({
  key: SELECTED_PERSONNEL_SEAT_TYPE,
  get: ({ get }) => {
    const parts = []
    const adultCount = get(adultCountState)
    const childCount = get(childCountState)
    const infantCount = get(infantCountState)
    const seatTypes = get(seatTypesState)

    const selectedSeatTypes = seatTypes.filter(({ selected }) => selected)

    if (adultCount > 0) {
      parts.push(`성인 ${adultCount}명`)
    }
    if (childCount > 0) {
      parts.push(`소아 ${childCount}명`)
    }
    if (infantCount > 0) {
      parts.push(`유아 ${infantCount}명`)
    }

    if (selectedSeatTypes.length === 5) {
      parts.push('전체')
    } else {
      selectedSeatTypes.forEach(({ selected, name }) => {
        if (selected) {
          parts.push(name)
        }
      })
    }

    return parts.join(', ')
  },
})
