import { motion } from 'framer-motion'
import { DimProps } from './types'

export const Dim = ({ onClick }: DimProps) => (
  <motion.div
    onClick={onClick}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.3 }}
    className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[10000] tw-bg-black tw-bg-opacity-50"
  />
)
