import { useEffect, useState } from 'react'

const hexToRgbArray = (hex: string): [number, number, number, number] => {
  const bigint = parseInt(hex.slice(1), 16)
  const red = (bigint >> 16) & 255
  const green = (bigint >> 8) & 255
  const blue = bigint & 255

  return [red / 255, green / 255, blue / 255, 1]
}

export const useLoadingAnimation = (colorHex?: string) => {
  const [loadingAnimation, setLoadingAnimation] = useState()

  useEffect(() => {
    let isMounted = true

    fetch('/animations/basic_loading_white.json')
      .then((res) => res.json())
      .then((data) => {
        if (isMounted) {
          if (colorHex) {
            data.layers.forEach((layer: Record<string, any>) => {
              layer.shapes?.forEach((shape: Record<string, any>) => {
                shape.it?.forEach((item: Record<string, any>) => {
                  if (item.ty === 'fl' && item.c && item.c.k) {
                    item.c.k = hexToRgbArray(colorHex)
                  }
                })
              })
            })
          }

          setLoadingAnimation(data)
        }
      })

    return () => {
      isMounted = false
    }
  }, [colorHex])

  return loadingAnimation
}
