import { Snackbar, useSnackbar } from '@socar-inc/socar-design-system'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export type ShowSnackbar = (content: string, bottom?: number) => void

interface SnackbarContext {
  showSnackbar: ShowSnackbar
}

const SnackbarContext = createContext<SnackbarContext | undefined>(undefined)

/**
 *
 * @returns showSnackbar - Snackbar를 띄우는 함수. content와 bottom을 인자로 받습니다. bottom은 optional 하며 default 값은 16입니다.
 */
export const useFlightSnackbar = () => {
  const context = useContext(SnackbarContext)
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }
  return context
}

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const { openSnackbar, ...snackbarProps } = useSnackbar()
  const [bottom, setBottom] = useState(16)

  const showSnackbar = useCallback(
    (content: string, bottom?: number) => {
      setBottom(bottom || 16)

      openSnackbar(content)
    },
    [openSnackbar]
  )

  const contextValue = useMemo(() => ({ showSnackbar }), [showSnackbar])

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <div
        className="tw-fixed tw-left-1/2 tw-w-full tw-min-w-[320px] tw-max-w-[420px] -tw-translate-x-1/2"
        style={{ bottom: `${bottom}px` }}>
        <Snackbar type="basic" {...snackbarProps} />
      </div>
    </SnackbarContext.Provider>
  )
}
