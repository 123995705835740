import { ARRIVAL, DEPARTURE } from '@src/store/constants/search/atomsKey'
import { TRAVEL_DESTINATION_DEFAULT } from '@src/store/constants/search/defaultValue'
import { storageEffect } from '@src/utils/storageEffect'
import { atom } from 'recoil'

export interface TravelDestination {
  code: string | null
  name: string | null
}

export const departureState = atom<TravelDestination>({
  key: DEPARTURE,
  default: TRAVEL_DESTINATION_DEFAULT,
  effects: [storageEffect('departure', 'session')],
})

export const arrivalState = atom<TravelDestination>({
  key: ARRIVAL,
  default: TRAVEL_DESTINATION_DEFAULT,
  effects: [storageEffect('arrival', 'session')],
})
