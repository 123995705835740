import { getDayOfWeek } from '@socar-inc/utils'

/**
 * @param date - Date
 * @returns YY. MM. DD. (요일)
 */
export const formatDateWithYearMonthDayAndDayOfWeek = (date: Date) => {
  const year = String(date.getFullYear()).slice(2)
  const month = date.getMonth() + 1
  const day = date.getDate()
  const dayOfWeek = getDayOfWeek(date.getTime())

  return `${year}. ${month}. ${day}. (${dayOfWeek})`
}

/**
 * @param date - Date
 * @returns MM. DD. (요일)
 */
export const formatDateWithMonthDayAndDayOfWeek = (date: Date) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const dayOfWeek = getDayOfWeek(date.getTime())

  return `${month}. ${day}. (${dayOfWeek})`
}

/**
 * @param date - Date
 * @returns YY. MM. DD.
 */
export const formatDateWithYearMonthDay = (date: Date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}. ${month}. ${day}`
}
