import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'

const BottomSheetValueContext = createContext(false)
const BottomSheetActionsContext = createContext<{
  actions?: {
    onOpen: () => void
    onClose: () => void
  }
}>({})

export interface BottomSheetProviderProps extends PropsWithChildren {
  onBeforeOpen?: () => void
  onBeforeClose?: () => void
}

export const BottomSheetProvider = ({
  children,
  onBeforeOpen,
  onBeforeClose,
}: BottomSheetProviderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const actions = useMemo(
    () => ({
      onOpen: async () => {
        if (onBeforeOpen) {
          await Promise.resolve(onBeforeOpen())
        }
        setIsOpen(true)
      },
      onClose: async () => {
        if (onBeforeClose) {
          await Promise.resolve(onBeforeClose())
        }
        setIsOpen(false)
      },
    }),
    [onBeforeClose, onBeforeOpen]
  )

  return (
    <BottomSheetActionsContext.Provider value={{ actions }}>
      <BottomSheetValueContext.Provider value={isOpen}>
        {children}
      </BottomSheetValueContext.Provider>
    </BottomSheetActionsContext.Provider>
  )
}

export const useBottomSheetState = () => {
  const isOpen = useContext(BottomSheetValueContext)
  if (isOpen === undefined) {
    throw new Error('<BottomSheet.Root> 컴포넌트로 감싸주세요.')
  }
  return { isOpen }
}

export const useBottomSheetActions = () => {
  const { actions } = useContext(BottomSheetActionsContext)
  if (actions === undefined) {
    throw new Error('<BottomSheet.Root> 컴포넌트로 감싸주세요.')
  }
  return actions
}
