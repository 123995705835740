import Image from 'next/image'
import { bannerVariant } from './constants'
import { BannerProps } from './types'
import { getImageDimensions } from './utils'
import Link from 'next/link'

export const Banner = ({ position, alt, src, linkUrl }: BannerProps) => {
  const { width, height } = getImageDimensions(position)

  return (
    <Link href={linkUrl} target="_blank">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        className={bannerVariant({ status: position })}
        width={width}
        height={height}
        src={src}
        alt={alt}
      />
    </Link>
  )
}
