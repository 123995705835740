import { useLottie } from 'lottie-react'
import { ReactNode } from 'react'

interface LoadingLottieProps {
  loadingAnimation: ReactNode
  height?: number
  width?: number
}

export const LoadingLottie = ({
  loadingAnimation,
  height = 24,
  width = 48,
}: LoadingLottieProps) => {
  const { View } = useLottie({
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    style: {
      margin: 'auto',
      height,
      width,
    },
  })
  return View
}
