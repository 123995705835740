/* tslint:disable */
/* eslint-disable */
/**
 * 
 * @export
 * @interface AirFareRulesDto
 */
export interface AirFareRulesDto {
    /**
     * 
     * @type {Rules}
     * @memberof AirFareRulesDto
     */
    outBoundRules: Rules;
    /**
     * 
     * @type {Rules}
     * @memberof AirFareRulesDto
     */
    inBoundRules?: Rules;
}
/**
 * 
 * @export
 * @interface AirFareRulesGetRequest
 */
export interface AirFareRulesGetRequest {
    /**
     * 가는편 항공사 코드
     * @type {string}
     * @memberof AirFareRulesGetRequest
     */
    outBoundAirCode: string;
    /**
     * 오는편 항공사 코드
     * @type {string}
     * @memberof AirFareRulesGetRequest
     */
    inBoundAirCode?: string;
}
/**
 * 항공사 목록
 * @export
 * @interface AirLineDto
 */
export interface AirLineDto {
    /**
     * 항공사 코드
     * @type {string}
     * @memberof AirLineDto
     */
    code: string;
    /**
     * 항공사 명
     * @type {string}
     * @memberof AirLineDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface AirPaxDiscountsDto
 */
export interface AirPaxDiscountsDto {
    /**
     * 출발 성인 할인 정보
     * @type {Array<DiscountDto>}
     * @memberof AirPaxDiscountsDto
     */
    depAdt: Array<DiscountDto>;
    /**
     * 도착 성인 할인 정보
     * @type {Array<DiscountDto>}
     * @memberof AirPaxDiscountsDto
     */
    arrAdt?: Array<DiscountDto>;
    /**
     * 출발 소아 할인 정보
     * @type {Array<DiscountDto>}
     * @memberof AirPaxDiscountsDto
     */
    depChd?: Array<DiscountDto>;
    /**
     * 도착 소아 할인 정보
     * @type {Array<DiscountDto>}
     * @memberof AirPaxDiscountsDto
     */
    arrChd?: Array<DiscountDto>;
    /**
     * 출발 유아 할인 정보
     * @type {Array<DiscountDto>}
     * @memberof AirPaxDiscountsDto
     */
    depInf?: Array<DiscountDto>;
    /**
     * 도착 유아 할인 정보
     * @type {Array<DiscountDto>}
     * @memberof AirPaxDiscountsDto
     */
    arrInf?: Array<DiscountDto>;
}
/**
 * 
 * @export
 * @interface AirTk110DaoProcessVO
 */
export interface AirTk110DaoProcessVO {
    /**
     * 발권 일자
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    issuedate?: string;
    /**
     * 티켓 번호
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    tktno?: string;
    /**
     * 항공사 코드
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    aircd?: string;
    /**
     * 항공사 숫자 코드
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    airnocd?: string;
    /**
     * 전체 여정 내용
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    allitincontent?: string;
    /**
     * 캐빈 좌석 등급
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    cabinseatgrad?: string;
    /**
     * 카드 금액
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    cardamt?: string;
    /**
     * 할인 금액
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    dscntamt?: string;
    /**
     * 운임
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    fare?: string;
    /**
     * 수수료
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    fee?: string;
    /**
     * 수수료 부가가치세
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    feevat?: string;
    /**
     * 발권 대행 수수료
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    issueproxyfee?: string;
    /**
     * 발권 대행 부가가치세
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    issueproxyvat?: string;
    /**
     * 여정 도착 일자
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    itinarrdate?: string;
    /**
     * 여정 출발 일자
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    itindepdate?: string;
    /**
     * 숫자 예약 번호
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    norsvno?: string;
    /**
     * 탑승객 영문 성 (한글 명 저장)
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    paxengfmnm?: string;
    /**
     * PNR 일련번호
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    pnrseqno?: string;
    /**
     * 판매 금액
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    saleamt?: string;
    /**
     * 판매 일자
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    saledate?: string;
    /**
     * 판매 수수료
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    salefee?: string;
    /**
     * 판매 수수료 부가가치세
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    salefeevat?: string;
    /**
     * 취급수수료
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    tasf?: string;
    /**
     * 세금 금액
     * @type {string}
     * @memberof AirTk110DaoProcessVO
     */
    taxamt?: string;
}
/**
 * 요청게시판
 * @export
 * @interface AnoRv910DaoProcessVO
 */
export interface AnoRv910DaoProcessVO {
    /**
     * 요청 일련번호
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    rqseqno?: string;
    /**
     * 답변 내용
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    anscontent?: string;
    /**
     * 답변 일시
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    ansdtm?: string;
    /**
     * 답변 여부
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    ansyn?: string;
    /**
     * 상담 구분
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    cnsltflag?: string;
    /**
     * 이메일 수신 여부
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    emailrsyn?: string;
    /**
     * PNR 일련번호
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    pnrseqno?: string;
    /**
     * 등록 일시
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    regdtm?: string;
    /**
     * 요청 코드
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    rqcd?: string;
    /**
     * 요청 내용
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    rqcontent?: string;
    /**
     * 요청 일시
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    rqdtm?: string;
    /**
     * 요청 처리 상태 코드
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    rqprocstatuscd?: string;
    /**
     * 요청 제목
     * @type {string}
     * @memberof AnoRv910DaoProcessVO
     */
    rqsubj?: string;
}
/**
 * 
 * @export
 * @interface AreaDto
 */
export interface AreaDto {
    /**
     * 
     * @type {string}
     * @memberof AreaDto
     */
    areaCd: string;
    /**
     * 
     * @type {string}
     * @memberof AreaDto
     */
    areaName: string;
    /**
     * 
     * @type {string}
     * @memberof AreaDto
     */
    cityCode: string;
    /**
     * 
     * @type {Name}
     * @memberof AreaDto
     */
    cityName: Name;
    /**
     * 
     * @type {string}
     * @memberof AreaDto
     */
    nationalCode: string;
}
/**
 * 
 * @export
 * @interface AttachmentDto
 */
export interface AttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    seqNo: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    size: string;
}
/**
 * 
 * @export
 * @interface Avail
 */
export interface Avail {
    /**
     * 여정 번호
     * @type {string}
     * @memberof Avail
     */
    itineraryNumber?: string;
    /**
     * 비행편
     * @type {string}
     * @memberof Avail
     */
    itineraryAirFlight?: string;
    /**
     * 비행 도시 구간
     * @type {string}
     * @memberof Avail
     */
    itinerarySegCity?: string;
    /**
     * 여정 출발일
     * @type {string}
     * @memberof Avail
     */
    itineraryDepDate?: string;
    /**
     * 여정 도착일
     * @type {string}
     * @memberof Avail
     */
    itineraryArrDate?: string;
    /**
     * 여정 좌석정보
     * @type {string}
     * @memberof Avail
     */
    itinerarySeatInfo?: string;
    /**
     * 좌석 상태
     * @type {string}
     * @memberof Avail
     */
    itinerarySeatStatus?: string;
}
/**
 * 
 * @export
 * @interface Avails
 */
export interface Avails {
    /**
     * 여정 번호
     * @type {string}
     * @memberof Avails
     */
    itineraryNo?: string;
    /**
     * seg no
     * @type {string}
     * @memberof Avails
     */
    seqNo?: string;
    /**
     * 항공사 코드
     * @type {string}
     * @memberof Avails
     */
    airCode?: string;
    /**
     * 항공사명
     * @type {string}
     * @memberof Avails
     */
    airName?: string;
    /**
     * 비행편 번호
     * @type {string}
     * @memberof Avails
     */
    flightNo?: string;
    /**
     * 출발 도시 코드
     * @type {string}
     * @memberof Avails
     */
    depCityCode?: string;
    /**
     * 출발 도시명
     * @type {string}
     * @memberof Avails
     */
    depCityName?: string;
    /**
     * 출발 공항 코드
     * @type {string}
     * @memberof Avails
     */
    depAirportCode?: string;
    /**
     * 출발 공항명
     * @type {string}
     * @memberof Avails
     */
    depAirportName?: string;
    /**
     * 출발일
     * @type {string}
     * @memberof Avails
     */
    depDate?: string;
    /**
     * 출발 시간
     * @type {string}
     * @memberof Avails
     */
    depTime?: string;
    /**
     * 출발 요일
     * @type {string}
     * @memberof Avails
     */
    depWeek?: string;
    /**
     * 도착 도시 코드
     * @type {string}
     * @memberof Avails
     */
    arrCityCode?: string;
    /**
     * 도착 도시명
     * @type {string}
     * @memberof Avails
     */
    arrCityName?: string;
    /**
     * 도착 공항 코드
     * @type {string}
     * @memberof Avails
     */
    arrAirportCode?: string;
    /**
     * 도착 공항명
     * @type {string}
     * @memberof Avails
     */
    arrAirportName?: string;
    /**
     * 도착일
     * @type {string}
     * @memberof Avails
     */
    arrDate?: string;
    /**
     * 도착 시간
     * @type {string}
     * @memberof Avails
     */
    arrTime?: string;
    /**
     * 도착 요일
     * @type {string}
     * @memberof Avails
     */
    arrWeek?: string;
    /**
     * 부킹 클래스
     * @type {string}
     * @memberof Avails
     */
    bookingClass?: string;
    /**
     * 좌석 수
     * @type {string}
     * @memberof Avails
     */
    seatCount?: string;
    /**
     * 좌석 상태
     * @type {string}
     * @memberof Avails
     */
    seatStatus?: string;
    /**
     * 공동 운항 코드
     * @type {string}
     * @memberof Avails
     */
    codeShare?: string;
    /**
     * 공동 운항 명
     * @type {string}
     * @memberof Avails
     */
    codeShareName?: string;
    /**
     * 공동 운항 텍스트
     * @type {string}
     * @memberof Avails
     */
    codesharetext?: string;
    /**
     * 좌석 등급
     * @type {string}
     * @memberof Avails
     */
    cabinClass?: string;
    /**
     * 좌석 등급명
     * @type {string}
     * @memberof Avails
     */
    cabinClassName?: string;
    /**
     * 숨은 경유지 코드
     * @type {string}
     * @memberof Avails
     */
    stopoverCityCode?: string;
    /**
     * 숨은 경유지 명
     * @type {string}
     * @memberof Avails
     */
    stopoverCityName?: string;
    /**
     * 수하물 정보
     * @type {string}
     * @memberof Avails
     */
    baggageInfo?: string;
}
/**
 * 
 * @export
 * @interface BannerDto
 */
export interface BannerDto {
    /**
     * 배너 일련번호
     * @type {number}
     * @memberof BannerDto
     */
    seqNo: number;
    /**
     * 배너 제목
     * @type {string}
     * @memberof BannerDto
     */
    title: string;
    /**
     * PC 이미지
     * @type {string}
     * @memberof BannerDto
     */
    pcImgPath?: string;
    /**
     * 모바일 이미지
     * @type {string}
     * @memberof BannerDto
     */
    mobileImgPath?: string;
    /**
     * PC 링크 이미지
     * @type {string}
     * @memberof BannerDto
     */
    pcLinkUrl?: string;
    /**
     * 모바일 링크 이미지
     * @type {string}
     * @memberof BannerDto
     */
    mobileLinkUrl?: string;
    /**
     * 배너 시작 일자
     * @type {string}
     * @memberof BannerDto
     */
    beginDate: string;
    /**
     * 배너 시작 시작
     * @type {string}
     * @memberof BannerDto
     */
    beginTime: string;
    /**
     * 배너 종료 일자
     * @type {string}
     * @memberof BannerDto
     */
    endDate: string;
    /**
     * 배너 종료 시간
     * @type {string}
     * @memberof BannerDto
     */
    endTime: string;
    /**
     * 새창 여부(N:새창, S:현재창)
     * @type {string}
     * @memberof BannerDto
     */
    newWindowFlag?: string;
    /**
     * 정렬 순서
     * @type {number}
     * @memberof BannerDto
     */
    sortOrder: number;
    /**
     * 노출 여부(Y:노출, N:미노출)
     * @type {string}
     * @memberof BannerDto
     */
    display: string;
    /**
     * 비고
     * @type {string}
     * @memberof BannerDto
     */
    remark?: string;
    /**
     * 배너 노출 위치(TOP: 메인 상단, BOTTOM: 메인 하단, LEFT: 메인 좌측, RIGHT: 메인 우측)
     * @type {string}
     * @memberof BannerDto
     */
    position: BannerDtoPosition;
}


/**
 * @export
 */
export const BannerDtoPosition = {
    TOP: 'TOP',
    BOTTOM: 'BOTTOM',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT'
} as const;
export type BannerDtoPosition = typeof BannerDtoPosition[keyof typeof BannerDtoPosition];

/**
 * 
 * @export
 * @interface BoardDetailDto
 */
export interface BoardDetailDto {
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    seqNo: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    categoryCode: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    categoryName: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    areaCode: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    areaName: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    searchCnt: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    writerId: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    writerName: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailDto
     */
    registeredAt: string;
    /**
     * 
     * @type {Array<AttachmentDto>}
     * @memberof BoardDetailDto
     */
    attachments: Array<AttachmentDto>;
}
/**
 * 
 * @export
 * @interface BoardDto
 */
export interface BoardDto {
    /**
     * 
     * @type {string}
     * @memberof BoardDto
     */
    seqNo: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDto
     */
    categoryCode: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDto
     */
    categoryName: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDto
     */
    areaCode: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDto
     */
    areaName: string;
    /**
     * 
     * @type {number}
     * @memberof BoardDto
     */
    searchCnt: number;
    /**
     * 
     * @type {string}
     * @memberof BoardDto
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDto
     */
    registeredAt: string;
}
/**
 * 
 * @export
 * @interface BoardsDto
 */
export interface BoardsDto {
    /**
     * 
     * @type {number}
     * @memberof BoardsDto
     */
    pageNum: number;
    /**
     * 
     * @type {number}
     * @memberof BoardsDto
     */
    totalPageNum: number;
    /**
     * 
     * @type {number}
     * @memberof BoardsDto
     */
    searchNum: number;
    /**
     * 
     * @type {Array<BoardDto>}
     * @memberof BoardsDto
     */
    boards: Array<BoardDto>;
}
/**
 * 
 * @export
 * @interface CalendarSetting
 */
export interface CalendarSetting {
    /**
     * 옵션 코드
     * @type {string}
     * @memberof CalendarSetting
     */
    optionCode: string;
    /**
     * 옵션 상세 코드
     * 16 : 국내선 달력팝업 예약가능일자 (Today+일), 10 : 국제선 달력팝업 예약가능일자 (Today+일), 13: 달력팝업 선택할수 있는최대일자 (Today+일)
     * @type {string}
     * @memberof CalendarSetting
     */
    optionCodeDetail: string;
    /**
     * 옵션 이름
     * @type {string}
     * @memberof CalendarSetting
     */
    optionName: string;
    /**
     * 옵션 값
     * @type {string}
     * @memberof CalendarSetting
     */
    optionValue: string;
}
/**
 * 
 * @export
 * @interface CancelReservationRequest
 */
export interface CancelReservationRequest {
    /**
     * 
     * @type {string}
     * @memberof CancelReservationRequest
     */
    pnrSeqNo: string;
    /**
     * 
     * @type {string}
     * @memberof CancelReservationRequest
     */
    cancelReason?: string;
    /**
     * 
     * @type {string}
     * @memberof CancelReservationRequest
     */
    etcCancelReason?: string;
}
/**
 * 
 * @export
 * @interface CancellationResultDto
 */
export interface CancellationResultDto {
    /**
     * PNR 일련번호
     * @type {string}
     * @memberof CancellationResultDto
     */
    pnrNo: string;
    /**
     * 취소 결과
     * @type {string}
     * @memberof CancellationResultDto
     */
    result: string;
    /**
     * 취소 결과 메시지
     * @type {string}
     * @memberof CancellationResultDto
     */
    resultMessage: string;
    /**
     * TASF 취소 결과 메세지
     * @type {string}
     * @memberof CancellationResultDto
     */
    tasfResultMessage: string;
}
/**
 * 
 * @export
 * @interface CardPaymentRequest
 */
export interface CardPaymentRequest {
    /**
     * 예약번호
     * @type {Array<string>}
     * @memberof CardPaymentRequest
     */
    pnrNoList: Array<string>;
    /**
     * 카드 소유주명
     * @type {string}
     * @memberof CardPaymentRequest
     */
    cardOwner: string;
    /**
     * 카드 종류
     * @type {string}
     * @memberof CardPaymentRequest
     */
    cardKind: string;
    /**
     * 카드 번호
     * @type {string}
     * @memberof CardPaymentRequest
     */
    cardNo: string;
    /**
     * 카드 유효기간(년)
     * @type {string}
     * @memberof CardPaymentRequest
     */
    cardValidYy: string;
    /**
     * 카드 유효기간(월)
     * @type {string}
     * @memberof CardPaymentRequest
     */
    cardValidMm: string;
    /**
     * 카드 할부개월수(00 :일시불, 두 자리 수 기입)
     * @type {string}
     * @memberof CardPaymentRequest
     */
    cardInstallmentMonths: string;
    /**
     * 카드 비밀번호 앞 두자리
     * @type {string}
     * @memberof CardPaymentRequest
     */
    cardPwd: string;
    /**
     * 카드 소유주 생년월일(YYMMDD)
     * @type {string}
     * @memberof CardPaymentRequest
     */
    cardOwnerBirth: string;
}
/**
 * 
 * @export
 * @interface CompleteReservationRequest
 */
export interface CompleteReservationRequest {
    /**
     * 출발편 항공사 코드
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    outBoundAirCode: string;
    /**
     * 도착편 항공사 코드
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    inboundAirCode?: string;
    /**
     * 같은 항공사 여부
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    isSameAirLine: string;
    /**
     * ART 번역 규정 여부
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    artgdsruleyn?: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    artruleelyn?: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    domartrulertyn?: string;
    /**
     * 예약자 이메일1
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    userEmail1: string;
    /**
     * 예약자 이메일2
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    userEmail2: string;
    /**
     * 예약자 핸드폰1
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    userPhone1: string;
    /**
     * 예약자 핸드폰2
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    userPhone2: string;
    /**
     * 예약자 핸드폰3
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    userPhone3: string;
    /**
     * 예약자 이름
     * @type {string}
     * @memberof CompleteReservationRequest
     */
    userName: string;
    /**
     * 탑승자 생년월일
     * @type {Array<string>}
     * @memberof CompleteReservationRequest
     */
    paxBirthDates: Array<string>;
    /**
     * 탑승자 이름
     * @type {Array<string>}
     * @memberof CompleteReservationRequest
     */
    paxFirstNames: Array<string>;
    /**
     * 탑승자 성
     * @type {Array<string>}
     * @memberof CompleteReservationRequest
     */
    paxLastNames: Array<string>;
    /**
     * 탑승자 국가 코드
     * @type {Array<string>}
     * @memberof CompleteReservationRequest
     */
    paxNationCodes: Array<string>;
    /**
     * 탑승자 성별
     * @type {Array<string>}
     * @memberof CompleteReservationRequest
     */
    paxSexes: Array<string>;
    /**
     * 탑승자 타입
     * @type {Array<string>}
     * @memberof CompleteReservationRequest
     */
    paxTypes: Array<string>;
    /**
     * 출발편 탑승자 할인 코드
     * @type {Array<string>}
     * @memberof CompleteReservationRequest
     */
    outBoundPaxDiscountCodes: Array<string>;
    /**
     * 출발편 탑승자 할인 텍스트
     * @type {Array<string>}
     * @memberof CompleteReservationRequest
     */
    outBoundPaxDiscountText: Array<string>;
    /**
     * 도착편 탑승자 할인 코드
     * @type {Array<string>}
     * @memberof CompleteReservationRequest
     */
    inBoundPaxDiscountCodes?: Array<string>;
    /**
     * 도착편 탑승자 할인 텍스트
     * @type {Array<string>}
     * @memberof CompleteReservationRequest
     */
    inBoundPaxDiscountText?: Array<string>;
}
/**
 * 
 * @export
 * @interface CompleteReservationResponse
 */
export interface CompleteReservationResponse {
    /**
     * 예약 성공 여부
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    isSuccess: string;
    /**
     * 예약 번호
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    reservationNo: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    pnrNo: string;
    /**
     * 이벤트 유형
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    eventType: string;
    /**
     * 국내선/국제선 구분 코드
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    diFlag: string;
    /**
     * 비자 사용 여부
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    visauseyn: string;
    /**
     * 여정 체크 값
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    fareCheckFlag: string;
    /**
     * 반환 URL 사용 여부
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    returnUrlFlag: string;
    /**
     * 예약 좌석 상태 (CONFIRMED: 확정, WAITING: 대기, UNAVAILABLE: 불가, 취소)
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    reservationStatus: CompleteReservationResponseReservationStatus;
    /**
     * 도착 일자
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    arrDate: string;
    /**
     * 도착 도시 코드
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    arrCityCode: string;
    /**
     * 출발 일자
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    depDate: string;
    /**
     * 출발 도시 코드
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    depCityCode: string;
    /**
     * 총 승객 수
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    totalPaxCount: string;
    /**
     * 사전입국 심사 여부
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    apisyn?: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    dom1: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    dom2: string;
    /**
     * 요금 확정 여부
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    isFareConfirmed: string;
    /**
     * API 등록 여부
     * @type {string}
     * @memberof CompleteReservationResponse
     */
    apisregyn?: string;
}


/**
 * @export
 */
export const CompleteReservationResponseReservationStatus = {
    UNKNOWN: 'UNKNOWN',
    CONFIRMED: 'CONFIRMED',
    WAITING: 'WAITING',
    UNAVAILABLE: 'UNAVAILABLE'
} as const;
export type CompleteReservationResponseReservationStatus = typeof CompleteReservationResponseReservationStatus[keyof typeof CompleteReservationResponseReservationStatus];

/**
 * 
 * @export
 * @interface CounselDto
 */
export interface CounselDto {
    /**
     * 요청 일련번호
     * @type {number}
     * @memberof CounselDto
     */
    rqSeqNo: number;
    /**
     * PNR 일련번호
     * @type {number}
     * @memberof CounselDto
     */
    pnrSeqNo: number;
    /**
     * 요청 코드
     * @type {string}
     * @memberof CounselDto
     */
    rqCd: string;
    /**
     * 요청 코드명
     * @type {string}
     * @memberof CounselDto
     */
    rqNm: string;
    /**
     * 요청 처리 상태 코드 (REQUESTING: 요청중, RECEIVED: 요청접수, COMPLETED: 완료, UNABLE_TO_PROCESS: 처리불가
     * @type {string}
     * @memberof CounselDto
     */
    rqProcStatusCd: CounselDtoRqProcStatusCd;
    /**
     * 요청 처리 상태 코드 명
     * @type {string}
     * @memberof CounselDto
     */
    rqProcStatusNm: string;
    /**
     * 요청 제목
     * @type {string}
     * @memberof CounselDto
     */
    rqSubj: string;
    /**
     * 요청 내용
     * @type {string}
     * @memberof CounselDto
     */
    rqContent: string;
    /**
     * 답변 내용
     * @type {string}
     * @memberof CounselDto
     */
    ansContent?: string;
    /**
     * 답변 사용자 ID
     * @type {string}
     * @memberof CounselDto
     */
    ansUsrId?: string;
    /**
     * 답변 여부
     * @type {string}
     * @memberof CounselDto
     */
    ansYn?: string;
    /**
     * 요청 일자
     * @type {string}
     * @memberof CounselDto
     */
    rqDd: string;
    /**
     * 요청 요일
     * @type {string}
     * @memberof CounselDto
     */
    rqWnm: string;
    /**
     * 요청 시간
     * @type {string}
     * @memberof CounselDto
     */
    rqTm: string;
    /**
     * 답변 일자
     * @type {string}
     * @memberof CounselDto
     */
    ansDd?: string;
    /**
     * 답변 요일
     * @type {string}
     * @memberof CounselDto
     */
    ansWnm?: string;
    /**
     * 답변 시간
     * @type {string}
     * @memberof CounselDto
     */
    ansTm?: string;
    /**
     * 출발 공항 코드
     * @type {string}
     * @memberof CounselDto
     */
    depAirPortCd: string;
    /**
     * 출발 공항 코드 명
     * @type {string}
     * @memberof CounselDto
     */
    depAirPortNm: string;
    /**
     * 도착 공항 코드
     * @type {string}
     * @memberof CounselDto
     */
    arrAirPortCd: string;
    /**
     * 도착 공항 코드 명
     * @type {string}
     * @memberof CounselDto
     */
    arrAirPortNm: string;
    /**
     * 예약 상태 코드
     * @type {string}
     * @memberof CounselDto
     */
    rasStatusCd: string;
    /**
     * 예약 상태 코드 명
     * @type {string}
     * @memberof CounselDto
     */
    rasStatusNm: string;
}


/**
 * @export
 */
export const CounselDtoRqProcStatusCd = {
    UNKNOWN: 'UNKNOWN',
    REQUESTING: 'REQUESTING',
    RECEIVED: 'RECEIVED',
    COMPLETED: 'COMPLETED',
    UNABLE_TO_PROCESS: 'UNABLE_TO_PROCESS'
} as const;
export type CounselDtoRqProcStatusCd = typeof CounselDtoRqProcStatusCd[keyof typeof CounselDtoRqProcStatusCd];

/**
 * 
 * @export
 * @interface CreateGuestSessionRequest
 */
export interface CreateGuestSessionRequest {
    /**
     * 네이버에서 온 요청인지 여부
     * @type {boolean}
     * @memberof CreateGuestSessionRequest
     */
    isFromNaver?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateGuestSessionRequest
     */
    mobile?: string;
}
/**
 * 
 * @export
 * @interface CreateGuestSessionWithAuthCodeRequest
 */
export interface CreateGuestSessionWithAuthCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateGuestSessionWithAuthCodeRequest
     */
    reservationNo: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGuestSessionWithAuthCodeRequest
     */
    mobile: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGuestSessionWithAuthCodeRequest
     */
    authCode: string;
}
/**
 * 
 * @export
 * @interface CreatePassengerInfoRequest
 */
export interface CreatePassengerInfoRequest {
    /**
     * 한글 성
     * @type {string}
     * @memberof CreatePassengerInfoRequest
     */
    lastNameKo: string;
    /**
     * 한글 이름
     * @type {string}
     * @memberof CreatePassengerInfoRequest
     */
    firstNameKo: string;
    /**
     * 영문 성
     * @type {string}
     * @memberof CreatePassengerInfoRequest
     */
    lastNameEn: string;
    /**
     * 영문 이름
     * @type {string}
     * @memberof CreatePassengerInfoRequest
     */
    firstNameEn: string;
    /**
     * 성별(남: 1, 여: 2)
     * @type {string}
     * @memberof CreatePassengerInfoRequest
     */
    sex: string;
    /**
     * 생년월일
     * @type {string}
     * @memberof CreatePassengerInfoRequest
     */
    birth: string;
    /**
     * 국적 코드
     * @type {string}
     * @memberof CreatePassengerInfoRequest
     */
    nationCode: string;
}
/**
 * 
 * @export
 * @interface CreateSessionResponse
 */
export interface CreateSessionResponse {
    /**
     * 
     * @type {Result}
     * @memberof CreateSessionResponse
     */
    result?: Result;
    /**
     * 
     * @type {MemberDto}
     * @memberof CreateSessionResponse
     */
    member: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionResponse
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionResponse
     */
    refreshToken: string;
}
/**
 * 
 * @export
 * @interface DeletePassengerInfoResponseDto
 */
export interface DeletePassengerInfoResponseDto {
    /**
     * 
     * @type {number}
     * @memberof DeletePassengerInfoResponseDto
     */
    seqNo: number;
    /**
     * 
     * @type {Result}
     * @memberof DeletePassengerInfoResponseDto
     */
    result: Result;
}
/**
 * 도착 유아 할인 정보
 * @export
 * @interface DiscountDto
 */
export interface DiscountDto {
    /**
     * 여정번호
     * @type {string}
     * @memberof DiscountDto
     */
    itineraryNo: string;
    /**
     * 항공사 코드
     * @type {string}
     * @memberof DiscountDto
     */
    airCode: string;
    /**
     * 탑승객 유형
     * @type {string}
     * @memberof DiscountDto
     */
    paxType: string;
    /**
     * 좌석 등급
     * @type {string}
     * @memberof DiscountDto
     */
    cabinClass: string;
    /**
     * 할인 코드
     * @type {string}
     * @memberof DiscountDto
     */
    discountCode: string;
    /**
     * 할인 명
     * @type {string}
     * @memberof DiscountDto
     */
    discountName: string;
}
/**
 * 
 * @export
 * @interface DomesticScheduleGetRequestDto
 */
export interface DomesticScheduleGetRequestDto {
    /**
     * 편도 : ONE_WAY 왕복 : ROUND_TRIP
     * @type {string}
     * @memberof DomesticScheduleGetRequestDto
     */
    itineraryType: DomesticScheduleGetRequestDtoItineraryType;
    /**
     * 조회 타입 
     * @type {string}
     * @memberof DomesticScheduleGetRequestDto
     */
    searchType: string;
    /**
     * 가는편 출발지 코드
     * @type {string}
     * @memberof DomesticScheduleGetRequestDto
     */
    depCityCodeOutBound: string;
    /**
     * 오는편 출발지 코드(왕복일 경우, 필수)
     * @type {string}
     * @memberof DomesticScheduleGetRequestDto
     */
    depCityCodeReturn?: string;
    /**
     * 가는편 도착지 코드
     * @type {string}
     * @memberof DomesticScheduleGetRequestDto
     */
    arrCityCodeOutBound: string;
    /**
     * 오는편 도착지 코드(왕복일 경우, 필수)
     * @type {string}
     * @memberof DomesticScheduleGetRequestDto
     */
    arrCityCodeReturn?: string;
    /**
     * 가는편 출발일자
     * @type {string}
     * @memberof DomesticScheduleGetRequestDto
     */
    depDateOutBound: string;
    /**
     * 오는편 출발일자(왕복일 경우, 필수)
     * @type {string}
     * @memberof DomesticScheduleGetRequestDto
     */
    depDateReturn?: string;
    /**
     * 성인 수
     * @type {number}
     * @memberof DomesticScheduleGetRequestDto
     */
    adultCount: number;
    /**
     * 소아 수
     * @type {number}
     * @memberof DomesticScheduleGetRequestDto
     */
    childCount: number;
    /**
     * 유아 수
     * @type {number}
     * @memberof DomesticScheduleGetRequestDto
     */
    infantCount: number;
    /**
     * 좌석 등급 : A(전체), Y(일반석), V(할인석), S(특가석), C(비즈니스석)
     * @type {string}
     * @memberof DomesticScheduleGetRequestDto
     */
    cabinClass: string;
}


/**
 * @export
 */
export const DomesticScheduleGetRequestDtoItineraryType = {
    ONE_WAY: 'ONE_WAY',
    ROUND_TRIP: 'ROUND_TRIP'
} as const;
export type DomesticScheduleGetRequestDtoItineraryType = typeof DomesticScheduleGetRequestDtoItineraryType[keyof typeof DomesticScheduleGetRequestDtoItineraryType];

/**
 * 
 * @export
 * @interface DomesticScheduleGetResponseDto
 */
export interface DomesticScheduleGetResponseDto {
    /**
     * 항공사 목록
     * @type {Array<AirLineDto>}
     * @memberof DomesticScheduleGetResponseDto
     */
    airlines: Array<AirLineDto>;
    /**
     * 가는편 스케줄 목록
     * @type {Array<ScheduleDto>}
     * @memberof DomesticScheduleGetResponseDto
     */
    outBoundSchedules: Array<ScheduleDto>;
    /**
     * 오는편 스케줄 목록
     * @type {Array<ScheduleDto>}
     * @memberof DomesticScheduleGetResponseDto
     */
    returnSchedules?: Array<ScheduleDto>;
}
/**
 * 
 * @export
 * @interface FareDto
 */
export interface FareDto {
    /**
     * 
     * @type {string}
     * @memberof FareDto
     */
    paxType?: string;
    /**
     * 탑승객 유형
     * @type {string}
     * @memberof FareDto
     */
    realPaxType?: string;
    /**
     * 항공요금
     * @type {string}
     * @memberof FareDto
     */
    airOriFee?: string;
    /**
     * 할인된 요금
     * @type {string}
     * @memberof FareDto
     */
    airFee?: string;
    /**
     * Upsell시 할인된 요금
     * @type {string}
     * @memberof FareDto
     */
    airBaseFee?: string;
    /**
     * 제세공과금
     * @type {string}
     * @memberof FareDto
     */
    taxFee?: string;
    /**
     * 유류할증료
     * @type {string}
     * @memberof FareDto
     */
    oilFee?: string;
    /**
     * 취급수수료
     * @type {string}
     * @memberof FareDto
     */
    tasfFee?: string;
    /**
     * 커미션
     * @type {string}
     * @memberof FareDto
     */
    comFee?: string;
    /**
     * 부가가치세
     * @type {string}
     * @memberof FareDto
     */
    vatFee?: string;
    /**
     * 총액
     * @type {string}
     * @memberof FareDto
     */
    sumFee?: string;
    /**
     * 탑승객 수
     * @type {string}
     * @memberof FareDto
     */
    paxCount?: string;
    /**
     * 총 요금
     * @type {string}
     * @memberof FareDto
     */
    totalSumFee?: string;
    /**
     * 성인 큐 금액
     * @type {string}
     * @memberof FareDto
     */
    qamt?: string;
    /**
     * 큐 차지 포함 요금
     * @type {string}
     * @memberof FareDto
     */
    qchargeincludeyn?: string;
    /**
     * 총 항공요금
     * @type {string}
     * @memberof FareDto
     */
    totalSumAirFee?: string;
    /**
     * 총 취급수수료
     * @type {string}
     * @memberof FareDto
     */
    totalTasfFee?: string;
    /**
     * 총액
     * @type {string}
     * @memberof FareDto
     */
    totalPaymentFee?: string;
    /**
     * 총 커미션
     * @type {string}
     * @memberof FareDto
     */
    totalComFee?: string;
    /**
     * 총 부가가치세
     * @type {string}
     * @memberof FareDto
     */
    totalVatFee?: string;
}
/**
 * 
 * @export
 * @interface FareItinerary
 */
export interface FareItinerary {
    /**
     * 여정 번호
     * @type {string}
     * @memberof FareItinerary
     */
    itineraryNumber?: string;
    /**
     * 여정 비행편
     * @type {string}
     * @memberof FareItinerary
     */
    paxType?: string;
    /**
     * 탑승객 유형
     * @type {string}
     * @memberof FareItinerary
     */
    realPaxType?: string;
    /**
     * 항공요금
     * @type {string}
     * @memberof FareItinerary
     */
    airOriFee?: string;
    /**
     * 할인된 요금
     * @type {string}
     * @memberof FareItinerary
     */
    airFee?: string;
    /**
     * 제세공과금
     * @type {string}
     * @memberof FareItinerary
     */
    taxFee?: string;
    /**
     * 유류할증료
     * @type {string}
     * @memberof FareItinerary
     */
    oilFee?: string;
    /**
     * 취급수수료
     * @type {string}
     * @memberof FareItinerary
     */
    tasfFee?: string;
    /**
     * 커미션
     * @type {string}
     * @memberof FareItinerary
     */
    comFee?: string;
    /**
     * 부가가치세
     * @type {string}
     * @memberof FareItinerary
     */
    vatFee?: string;
    /**
     * 총액
     * @type {string}
     * @memberof FareItinerary
     */
    sumFee?: string;
    /**
     * 탑승객 수
     * @type {string}
     * @memberof FareItinerary
     */
    paxCount?: string;
    /**
     * 총 요금
     * @type {string}
     * @memberof FareItinerary
     */
    totalSumFee?: string;
    /**
     * 성인 큐 금액
     * @type {string}
     * @memberof FareItinerary
     */
    qamt?: string;
    /**
     * 총 항공금액
     * @type {string}
     * @memberof FareItinerary
     */
    totalSumAirFee?: string;
    /**
     * 총 취급수수료
     * @type {string}
     * @memberof FareItinerary
     */
    totalTasfFee?: string;
    /**
     * 총액
     * @type {string}
     * @memberof FareItinerary
     */
    totalPaymentFee?: string;
    /**
     * 총 커미션
     * @type {string}
     * @memberof FareItinerary
     */
    totalComFee?: string;
    /**
     * 총 부가가치세
     * @type {string}
     * @memberof FareItinerary
     */
    totalVatFee?: string;
}
/**
 * 탑승객요금
 * @export
 * @interface FarePax
 */
export interface FarePax {
    /**
     * 탑승객 번호
     * @type {string}
     * @memberof FarePax
     */
    paxno: string;
    /**
     * 유류할증료
     * @type {string}
     * @memberof FarePax
     */
    baf: string;
    /**
     * 카드 결제 금액
     * @type {string}
     * @memberof FarePax
     */
    cardpayamt: string;
    /**
     * 수수료
     * @type {string}
     * @memberof FarePax
     */
    fee: string;
    /**
     * 수수료 부가가치세
     * @type {string}
     * @memberof FarePax
     */
    feevat: string;
    /**
     * 유아 동반 여권 여부
     * @type {string}
     * @memberof FarePax
     */
    infacmpnyppyn: string;
    /**
     * 부모 탑승객 번호
     * @type {string}
     * @memberof FarePax
     */
    parntspaxno: string;
    /**
     * 탑승객 나이 구분
     * @type {string}
     * @memberof FarePax
     */
    paxageflag: string;
    /**
     * 탑승객 생년월일
     * @type {string}
     * @memberof FarePax
     */
    paxbirth: string;
    /**
     * 탑승객 영문 성 (한글 명 대한항공을 제외한 항공사 3자 다 저장
     * @type {string}
     * @memberof FarePax
     */
    paxengfmnm: string;
    /**
     * 탑승객 영문 명
     * @type {string}
     * @memberof FarePax
     */
    paxengnm: string;
    /**
     * 탑승객 유아 동반 여부
     * @type {string}
     * @memberof FarePax
     */
    paxinfacmpnyyn: string;
    /**
     * 탑승객 국가 코드
     * @type {string}
     * @memberof FarePax
     */
    paxnacd: string;
    /**
     * 탑승객 성별
     * @type {string}
     * @memberof FarePax
     */
    paxsex: string;
    /**
     * 판매 할인 금액
     * @type {string}
     * @memberof FarePax
     */
    saledscntamt: string;
    /**
     * 판매 수수료
     * @type {string}
     * @memberof FarePax
     */
    salefee: string;
    /**
     * 판매 수수료 부가가치세
     * @type {string}
     * @memberof FarePax
     */
    salefeevat: string;
    /**
     * 판매 네트 금액
     * @type {string}
     * @memberof FarePax
     */
    salenetamt: string;
    /**
     * 판매 세금 금액
     * @type {string}
     * @memberof FarePax
     */
    saletaxamt: string;
    /**
     * 판매 총 금액
     * @type {string}
     * @memberof FarePax
     */
    saletotamt: string;
    /**
     * 취급수수료
     * @type {string}
     * @memberof FarePax
     */
    tasf: string;
    /**
     * 국내선 할인 정보
     * @type {string}
     * @memberof FarePax
     */
    domdscntinfo: string;
    /**
     * 국내선 승객 할인 코드
     * @type {string}
     * @memberof FarePax
     */
    dompaxdscntcd: string;
    /**
     * 탑승객 나이 구분 명
     * @type {string}
     * @memberof FarePax
     */
    paxageflagnm: string;
    /**
     * 탑승객 성별 명
     * @type {string}
     * @memberof FarePax
     */
    paxsexnm: string;
}
/**
 * 
 * @export
 * @interface FindGuestReservationRequest
 */
export interface FindGuestReservationRequest {
    /**
     * 예약번호
     * @type {string}
     * @memberof FindGuestReservationRequest
     */
    reservationNo: string;
    /**
     * 휴대폰번호
     * @type {string}
     * @memberof FindGuestReservationRequest
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface FlightSeg
 */
export interface FlightSeg {
    /**
     * 도착 공항 코드
     * @type {string}
     * @memberof FlightSeg
     */
    arrAirportCd: string;
    /**
     * 도착 공항 명
     * @type {string}
     * @memberof FlightSeg
     */
    arrAirportNm: string;
    /**
     * 도착 일자
     * @type {string}
     * @memberof FlightSeg
     */
    arrDate: string;
    /**
     * 도착 요일
     * @type {string}
     * @memberof FlightSeg
     */
    arrWeekday: string;
    /**
     * 출발 공항 코드
     * @type {string}
     * @memberof FlightSeg
     */
    depAirportCd: string;
    /**
     * 출발 공항 명
     * @type {string}
     * @memberof FlightSeg
     */
    depAirportNm: string;
    /**
     * 출발 일자
     * @type {string}
     * @memberof FlightSeg
     */
    depDate: string;
    /**
     * 출발 요일
     * @type {string}
     * @memberof FlightSeg
     */
    depWeekday: string;
    /**
     * 판매 항공사 코드
     * @type {string}
     * @memberof FlightSeg
     */
    saleAirCd: string;
    /**
     * 판매 항공사 명
     * @type {string}
     * @memberof FlightSeg
     */
    saleAirNm: string;
}
/**
 * 
 * @export
 * @interface FundamentalCodeDto
 */
export interface FundamentalCodeDto {
    /**
     * 코드
     * @type {string}
     * @memberof FundamentalCodeDto
     */
    code: string;
    /**
     * 이름
     * @type {string}
     * @memberof FundamentalCodeDto
     */
    name1: string;
    /**
     * 이름2
     * @type {string}
     * @memberof FundamentalCodeDto
     */
    name2?: string;
    /**
     * 참조코드1
     * @type {string}
     * @memberof FundamentalCodeDto
     */
    referCode1?: string;
    /**
     * 참조코드2
     * @type {string}
     * @memberof FundamentalCodeDto
     */
    referCode2?: string;
    /**
     * 참조값1
     * @type {string}
     * @memberof FundamentalCodeDto
     */
    referValue1?: string;
    /**
     * 참조값2
     * @type {string}
     * @memberof FundamentalCodeDto
     */
    referValue2?: string;
}
/**
 * 
 * @export
 * @interface GeneralRuleDto
 */
export interface GeneralRuleDto {
    /**
     * 국내/국제 구분 코드
     * @type {string}
     * @memberof GeneralRuleDto
     */
    domIntCode: string;
    /**
     * 국내/국제 구분 코드명
     * @type {string}
     * @memberof GeneralRuleDto
     */
    domIntName: string;
    /**
     * 규정명
     * @type {string}
     * @memberof GeneralRuleDto
     */
    ruleName: string;
    /**
     * 규정 내용
     * @type {string}
     * @memberof GeneralRuleDto
     */
    ruleContent: string;
}
/**
 * 
 * @export
 * @interface GetMemberReservationsResponse
 */
export interface GetMemberReservationsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetMemberReservationsResponse
     */
    totalPage: number;
    /**
     * 
     * @type {number}
     * @memberof GetMemberReservationsResponse
     */
    totalCnt: number;
    /**
     * 
     * @type {number}
     * @memberof GetMemberReservationsResponse
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof GetMemberReservationsResponse
     */
    currentPage: number;
    /**
     * 
     * @type {Array<ReservationDto>}
     * @memberof GetMemberReservationsResponse
     */
    reservations: Array<ReservationDto>;
}
/**
 * 
 * @export
 * @interface GetScheduleFromNaverLandingPageRequest
 */
export interface GetScheduleFromNaverLandingPageRequest {
    /**
     * 여정(0: 편도,1: 왕복)
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    itin?: string;
    /**
     * 가는편 출발지 공항코드
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    depcity0: string;
    /**
     * 가는편 도착지 공항코드
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    arrcity0: string;
    /**
     * 가는편 출발일시
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    depdate0: string;
    /**
     * 가는편 도착일시
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    arrdate0: string;
    /**
     * 가는편 항공사코드
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    carcode0: string;
    /**
     * 가는편 비행편명
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    fitname0: string;
    /**
     * 가는편 남은 좌석수
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    seatcnt0: string;
    /**
     * 가는편 성인수
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    acnt0: string;
    /**
     * 가는편 소아수
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    ccnt0: string;
    /**
     * 가는편 유아수
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    icnt0: string;
    /**
     * 가는편 좌석등급
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    seatclass0: string;
    /**
     * 가는편 성인요금
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    adultfare0: string;
    /**
     * 가는편 소아요금
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    childfare0?: string;
    /**
     * 가는편 성인 유류할증료
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    afuel0: string;
    /**
     * 가는편 성인 제세공과금
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    atax0: string;
    /**
     * 가는편 소아 유류할증료
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    cfuel0?: string;
    /**
     * 가는편 소아 제세공과금
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    ctax0?: string;
    /**
     * 가는편 발권대행 수수료
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    pubfee0: string;
    /**
     * 가는편 기타
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    etc0?: string;
    /**
     * 오는편 출발지 공항코드
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    depcity1?: string;
    /**
     * 오는편 도착지 공항코드
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    arrcity1?: string;
    /**
     * 오는편 출발일시
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    depdate1?: string;
    /**
     * 오는편 도착일시
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    arrdate1?: string;
    /**
     * 오는편 항공사코드
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    carcode1?: string;
    /**
     * 오는편 비행편명
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    fitname1?: string;
    /**
     * 오는편 남은 좌석수
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    seatcnt1?: string;
    /**
     * 오는편 성인수
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    acnt1?: string;
    /**
     * 오는편 소아수
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    ccnt1?: string;
    /**
     * 오는편 유아수
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    icnt1?: string;
    /**
     * 오는편 좌석등급
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    seatclass1?: string;
    /**
     * 오는편 성인요금
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    adultfare1?: string;
    /**
     * 오는편 소아요금
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    childfare1?: string;
    /**
     * 오는편 성인 유류할증료
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    afuel1?: string;
    /**
     * 오는편 성인 제세공과금
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    atax1?: string;
    /**
     * 오는편 소아 유류할증료
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    cfuel1?: string;
    /**
     * 오는편 소아 제세공과금
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    ctax1?: string;
    /**
     * 오는편 발권대행 수수료
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    pubfee1?: string;
    /**
     * 오는편 기타
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    etc1?: string;
    /**
     * naver 에서 보내주는 임의의 암호화된 값
     * @type {string}
     * @memberof GetScheduleFromNaverLandingPageRequest
     */
    nlabel?: string;
}
/**
 * 
 * @export
 * @interface GuideMessageDto
 */
export interface GuideMessageDto {
    /**
     * 문구상세번호
     * @type {string}
     * @memberof GuideMessageDto
     */
    msgCode?: GuideMessageDtoMsgCode;
    /**
     * 제목
     * @type {string}
     * @memberof GuideMessageDto
     */
    title: string;
    /**
     * 문구
     * @type {string}
     * @memberof GuideMessageDto
     */
    msg: string;
}


/**
 * @export
 */
export const GuideMessageDtoMsgCode = {
    AIRFARE: 'AIRFARE',
    PASSENGER_INFO: 'PASSENGER_INFO',
    PAYMENT_REQUEST: 'PAYMENT_REQUEST',
    PAYMENT_COMPLETE: 'PAYMENT_COMPLETE',
    TICKET_ISSUED_INTL: 'TICKET_ISSUED_INTL',
    TICKET_ISSUED_DOM: 'TICKET_ISSUED_DOM',
    PRE_PAYMENT_AGREEMENT_INTL: 'PRE_PAYMENT_AGREEMENT_INTL',
    REFUND_INFO: 'REFUND_INFO',
    SPECIAL_MEAL_REQUEST_KE: 'SPECIAL_MEAL_REQUEST_KE',
    PRE_PAYMENT_AGREEMENT_DOM: 'PRE_PAYMENT_AGREEMENT_DOM',
    PRE_RESERVATION_CHECK: 'PRE_RESERVATION_CHECK'
} as const;
export type GuideMessageDtoMsgCode = typeof GuideMessageDtoMsgCode[keyof typeof GuideMessageDtoMsgCode];

/**
 * 
 * @export
 * @interface HolidayDto
 */
export interface HolidayDto {
    /**
     * 
     * @type {string}
     * @memberof HolidayDto
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof HolidayDto
     */
    description: string;
}
/**
 * 
 * @export
 * @interface HolidaysGetResponseDto
 */
export interface HolidaysGetResponseDto {
    /**
     * 
     * @type {{ [key: string]: Array<HolidayDto>; }}
     * @memberof HolidaysGetResponseDto
     */
    holidayMap: { [key: string]: Array<HolidayDto>; };
    /**
     * 
     * @type {Array<CalendarSetting>}
     * @memberof HolidaysGetResponseDto
     */
    calendarSettings: Array<CalendarSetting>;
}
/**
 * 
 * @export
 * @interface InvoiceDto
 */
export interface InvoiceDto {
    /**
     * 예약번호
     * @type {string}
     * @memberof InvoiceDto
     */
    rsvNo: string;
    /**
     * 판매 총 금액
     * @type {string}
     * @memberof InvoiceDto
     */
    saleTotAmt: string;
    /**
     * 결제 일시
     * @type {string}
     * @memberof InvoiceDto
     */
    paymentDateTime: string;
    /**
     * 
     * @type {Array<FlightSeg>}
     * @memberof InvoiceDto
     */
    flightSegList?: Array<FlightSeg>;
    /**
     * 
     * @type {Array<PaymentDetail>}
     * @memberof InvoiceDto
     */
    paymentDetail?: Array<PaymentDetail>;
}
/**
 * 
 * @export
 * @interface InvoiceEmailRequest
 */
export interface InvoiceEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoiceEmailRequest
     */
    pnrNo: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceEmailRequest
     */
    recipientEmail: string;
}
/**
 * 
 * @export
 * @interface MemberDto
 */
export interface MemberDto {
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    phone: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberDto
     */
    isGuest: boolean;
}
/**
 * 
 * @export
 * @interface Name
 */
export interface Name {
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    kr: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    en: string;
}
/**
 * 
 * @export
 * @interface NationDto
 */
export interface NationDto {
    /**
     * 
     * @type {string}
     * @memberof NationDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof NationDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface NaverPayRequest
 */
export interface NaverPayRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof NaverPayRequest
     */
    pnrNo: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NaverPayRequest
     */
    reservationId: string;
    /**
     * 
     * @type {string}
     * @memberof NaverPayRequest
     */
    tempReceiptId: string;
}
/**
 * 
 * @export
 * @interface NaverPayResponseDto
 */
export interface NaverPayResponseDto {
    /**
     * pnr 일련번호
     * @type {string}
     * @memberof NaverPayResponseDto
     */
    pnrNo: string;
    /**
     * 항공사 코드
     * @type {string}
     * @memberof NaverPayResponseDto
     */
    airCode: string;
    /**
     * 항공 운임
     * @type {number}
     * @memberof NaverPayResponseDto
     */
    airPaymentAmount: number;
    /**
     * 항공 운임 결제 메시지
     * @type {string}
     * @memberof NaverPayResponseDto
     */
    airPaymentMessage: string;
    /**
     * TASF 요금
     * @type {number}
     * @memberof NaverPayResponseDto
     */
    tasfPaymentAmount: number;
    /**
     * TASF 요금 결제 메시지
     * @type {string}
     * @memberof NaverPayResponseDto
     */
    tasfPaymentMessage: string;
}
/**
 * 
 * @export
 * @interface OneWayReservationDetailDto
 */
export interface OneWayReservationDetailDto {
    /**
     * 
     * @type {ReservationDetail}
     * @memberof OneWayReservationDetailDto
     */
    reservationDetail?: ReservationDetail;
    /**
     * 탑승객요금
     * @type {Array<FarePax>}
     * @memberof OneWayReservationDetailDto
     */
    farePaxList?: Array<FarePax>;
    /**
     * 입금 환불
     * @type {Array<ReservationDepositPrint>}
     * @memberof OneWayReservationDetailDto
     */
    reservationDepositPrintList?: Array<ReservationDepositPrint>;
    /**
     * 
     * @type {Array<AirTk110DaoProcessVO>}
     * @memberof OneWayReservationDetailDto
     */
    airTicketProcessList?: Array<AirTk110DaoProcessVO>;
    /**
     * 
     * @type {Array<Seg>}
     * @memberof OneWayReservationDetailDto
     */
    segList?: Array<Seg>;
    /**
     * 요청게시판
     * @type {Array<AnoRv910DaoProcessVO>}
     * @memberof OneWayReservationDetailDto
     */
    requestBoardProcessList?: Array<AnoRv910DaoProcessVO>;
    /**
     * 결제 전 동의 사항
     * @type {string}
     * @memberof OneWayReservationDetailDto
     */
    payAgreeOptionConfigValue?: string;
    /**
     * 결제요청 안내문구
     * @type {string}
     * @memberof OneWayReservationDetailDto
     */
    payInfoOptionConfigValue?: string;
    /**
     * 결제 시간 제한 초과 여부
     * @type {string}
     * @memberof OneWayReservationDetailDto
     */
    paytloveryn?: string;
}
/**
 * 
 * @export
 * @interface PassengerInfoDto
 */
export interface PassengerInfoDto {
    /**
     * 승객 일련번호
     * @type {number}
     * @memberof PassengerInfoDto
     */
    passengerSeqNo: number;
    /**
     * 승객 한글 이름
     * @type {string}
     * @memberof PassengerInfoDto
     */
    firstNameKo: string;
    /**
     * 승객 한글 성
     * @type {string}
     * @memberof PassengerInfoDto
     */
    lastNameKo: string;
    /**
     * 승객 영문 이름
     * @type {string}
     * @memberof PassengerInfoDto
     */
    firstNameEn: string;
    /**
     * 승객 영문 성
     * @type {string}
     * @memberof PassengerInfoDto
     */
    lastNameEn: string;
    /**
     * 승객 성별(1: 남성, 2: 여성)
     * @type {string}
     * @memberof PassengerInfoDto
     */
    sex: string;
    /**
     * 승객 생년월일
     * @type {string}
     * @memberof PassengerInfoDto
     */
    birth: string;
    /**
     * 승객 국적 코드
     * @type {string}
     * @memberof PassengerInfoDto
     */
    nationCode: string;
}
/**
 * 
 * @export
 * @interface PaymentDetail
 */
export interface PaymentDetail {
    /**
     * PNR 일련번호
     * @type {string}
     * @memberof PaymentDetail
     */
    pnrNo: string;
    /**
     * 결제구분코드(AIR : 항공운임 , TASF :  항공수수료)
     * @type {string}
     * @memberof PaymentDetail
     */
    paymentType: PaymentDetailPaymentType;
    /**
     * 결제 방법 코드
     * @type {string}
     * @memberof PaymentDetail
     */
    paymentMethodCode: string;
    /**
     * 결제 방법 명
     * @type {string}
     * @memberof PaymentDetail
     */
    paymentMethodName: string;
    /**
     * 결제 총 금액
     * @type {string}
     * @memberof PaymentDetail
     */
    paymentTotalAmount: string;
    /**
     * 결제 상태(BEFORE_PAYMENT: 결제 요청 전, COMPLETED: 결제 완료)
     * @type {string}
     * @memberof PaymentDetail
     */
    paymentStatus: PaymentDetailPaymentStatus;
    /**
     * 결제 일시
     * @type {string}
     * @memberof PaymentDetail
     */
    paymentDateTime: string;
    /**
     * 카드 결제 금액
     * @type {string}
     * @memberof PaymentDetail
     */
    cardPaymentAmount: string;
    /**
     * 카드 종류
     * @type {string}
     * @memberof PaymentDetail
     */
    cardType?: string;
    /**
     * 카드 종류 명
     * @type {string}
     * @memberof PaymentDetail
     */
    cardTypeName: string;
    /**
     * 카드 은행
     * @type {string}
     * @memberof PaymentDetail
     */
    cardBank?: string;
    /**
     * 카드 은행 명
     * @type {string}
     * @memberof PaymentDetail
     */
    cardBankName: string;
    /**
     * 카드 번호
     * @type {string}
     * @memberof PaymentDetail
     */
    cardNo: string;
    /**
     * 카드 할부 개월 수
     * @type {string}
     * @memberof PaymentDetail
     */
    cardInstallationPlan?: string;
    /**
     * 현금 결제 금액
     * @type {string}
     * @memberof PaymentDetail
     */
    cashPaymentAmount: string;
    /**
     * 입금 은행 명
     * @type {string}
     * @memberof PaymentDetail
     */
    depositBankName?: string;
    /**
     * 입금 계좌 번호
     * @type {string}
     * @memberof PaymentDetail
     */
    depositBankAccountNo?: string;
    /**
     * 현금 영수증 번호
     * @type {string}
     * @memberof PaymentDetail
     */
    cashReceiptNo?: string;
    /**
     * 현금 영수증 종류 명
     * @type {string}
     * @memberof PaymentDetail
     */
    cashReceiptType?: string;
    /**
     * 현금 영수증 사용 목적
     * @type {string}
     * @memberof PaymentDetail
     */
    cashReceiptUsage?: string;
    /**
     * 상품권 포인트 명
     * @type {string}
     * @memberof PaymentDetail
     */
    giftCertificatePointName?: string;
    /**
     * 상품권 포인트 금액
     * @type {string}
     * @memberof PaymentDetail
     */
    giftCertificatePointAmount: string;
    /**
     * 카드 승인 번호
     * @type {string}
     * @memberof PaymentDetail
     */
    cardConfirmNo?: string;
    /**
     * 카드 승인 일시
     * @type {string}
     * @memberof PaymentDetail
     */
    cardConfirmDateTime?: string;
}


/**
 * @export
 */
export const PaymentDetailPaymentType = {
    AIR: 'AIR',
    TASF: 'TASF'
} as const;
export type PaymentDetailPaymentType = typeof PaymentDetailPaymentType[keyof typeof PaymentDetailPaymentType];

/**
 * @export
 */
export const PaymentDetailPaymentStatus = {
    BEFORE_PAYMENT: 'BEFORE_PAYMENT',
    COMPLETED: 'COMPLETED'
} as const;
export type PaymentDetailPaymentStatus = typeof PaymentDetailPaymentStatus[keyof typeof PaymentDetailPaymentStatus];

/**
 * 
 * @export
 * @interface PaymentDetailDto
 */
export interface PaymentDetailDto {
    /**
     * PNR 일련번호
     * @type {string}
     * @memberof PaymentDetailDto
     */
    pnrNo: string;
    /**
     * 결제일련번호
     * @type {string}
     * @memberof PaymentDetailDto
     */
    paymentRequestNo: string;
    /**
     * 결제구분코드(AIR : 항공운임 , TASF :  항공수수료)
     * @type {string}
     * @memberof PaymentDetailDto
     */
    paymentType: PaymentDetailDtoPaymentType;
    /**
     * 수수료 일련 번호
     * @type {string}
     * @memberof PaymentDetailDto
     */
    feeNo?: string;
    /**
     * 결제 방법 코드
     * @type {string}
     * @memberof PaymentDetailDto
     */
    paymentMethodCode: string;
    /**
     * 결제 방법 명
     * @type {string}
     * @memberof PaymentDetailDto
     */
    paymentMethodName: string;
    /**
     * 결제 총 금액
     * @type {string}
     * @memberof PaymentDetailDto
     */
    paymentTotalAmount: string;
    /**
     * 결제 상태(BEFORE_PAYMENT: 결제 요청 전, COMPLETED: 결제 완료)
     * @type {string}
     * @memberof PaymentDetailDto
     */
    paymentStatus: PaymentDetailDtoPaymentStatus;
    /**
     * 결제 일시
     * @type {string}
     * @memberof PaymentDetailDto
     */
    paymentDateTime: string;
    /**
     * 카드 결제 금액
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardPaymentAmount: string;
    /**
     * 카드 종류
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardType?: string;
    /**
     * 카드 종류 명
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardTypeName: string;
    /**
     * 카드 은행
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardBank?: string;
    /**
     * 카드 은행 명
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardBankName: string;
    /**
     * 카드 번호
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardNo: string;
    /**
     * 카드 소유주 동일 여부
     * @type {string}
     * @memberof PaymentDetailDto
     */
    isSameCardOwner: string;
    /**
     * 카드 소유주
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardOwner?: string;
    /**
     * 카드 소유주 주민등록번호
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardOwnerSsn?: string;
    /**
     * 카드 할부 개월 수
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardInstallationPlan?: string;
    /**
     * 카드 유효기간 년
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardExpirationYear: string;
    /**
     * 카드 유효기간 월
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardExpirationMonth: string;
    /**
     * 현금 결제 금액
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cashPaymentAmount: string;
    /**
     * 입금 은행 명
     * @type {string}
     * @memberof PaymentDetailDto
     */
    depositBankName?: string;
    /**
     * 입금 계좌 번호
     * @type {string}
     * @memberof PaymentDetailDto
     */
    depositBankAccountNo?: string;
    /**
     * 현금 영수증 번호
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cashReceiptNo?: string;
    /**
     * 현금 영수증 종류 명
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cashReceiptType?: string;
    /**
     * 현금 영수증 사용 목적
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cashReceiptUsage?: string;
    /**
     * 상품권 포인트 명
     * @type {string}
     * @memberof PaymentDetailDto
     */
    giftCertificatePointName?: string;
    /**
     * 상품권 포인트 금액
     * @type {string}
     * @memberof PaymentDetailDto
     */
    giftCertificatePointAmount: string;
    /**
     * 카드 비밀번호
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardPassword?: string;
    /**
     * 취급 수수료
     * @type {string}
     * @memberof PaymentDetailDto
     */
    tasf: string;
    /**
     * 금액 구분( 1 : 항공권, 2 : tasf )
     * @type {string}
     * @memberof PaymentDetailDto
     */
    amountType: string;
    /**
     * 등록 사용자 ID
     * @type {string}
     * @memberof PaymentDetailDto
     */
    memberId: string;
    /**
     * 등록 일시
     * @type {string}
     * @memberof PaymentDetailDto
     */
    registeredDateTime: string;
    /**
     * 탑승객 영문 성
     * @type {string}
     * @memberof PaymentDetailDto
     */
    paxLastName?: string;
    /**
     * 탑승객 영문 이름
     * @type {string}
     * @memberof PaymentDetailDto
     */
    paxFirstName?: string;
    /**
     * 결제 그룹 번호
     * @type {string}
     * @memberof PaymentDetailDto
     */
    paymentGroupNo?: string;
    /**
     * 카드 승인 번호
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardConfirmNo?: string;
    /**
     * 카드 승인 일시
     * @type {string}
     * @memberof PaymentDetailDto
     */
    cardConfirmDateTime?: string;
}


/**
 * @export
 */
export const PaymentDetailDtoPaymentType = {
    AIR: 'AIR',
    TASF: 'TASF'
} as const;
export type PaymentDetailDtoPaymentType = typeof PaymentDetailDtoPaymentType[keyof typeof PaymentDetailDtoPaymentType];

/**
 * @export
 */
export const PaymentDetailDtoPaymentStatus = {
    BEFORE_PAYMENT: 'BEFORE_PAYMENT',
    COMPLETED: 'COMPLETED'
} as const;
export type PaymentDetailDtoPaymentStatus = typeof PaymentDetailDtoPaymentStatus[keyof typeof PaymentDetailDtoPaymentStatus];

/**
 * 
 * @export
 * @interface PaymentDto
 */
export interface PaymentDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    pnrNo: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    stockAirCd: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    airPayment: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    airStatus: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    tasfPayment: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    tasfStatus: string;
}
/**
 * 
 * @export
 * @interface RegisterCounselRequest
 */
export interface RegisterCounselRequest {
    /**
     * PNR 일련번호
     * @type {string}
     * @memberof RegisterCounselRequest
     */
    pnrSeqNo: string;
    /**
     * 상담 분류(기초코드:N31)
     * @type {string}
     * @memberof RegisterCounselRequest
     */
    code: string;
    /**
     * 요청 내용
     * @type {string}
     * @memberof RegisterCounselRequest
     */
    content: string;
}
/**
 * 
 * @export
 * @interface RegisterCounselResult
 */
export interface RegisterCounselResult {
    /**
     * 
     * @type {string}
     * @memberof RegisterCounselResult
     */
    pnrSeqNo: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCounselResult
     */
    rqSeqNo: string;
}
/**
 * 
 * @export
 * @interface RegisterSelectedFlightRequestDto
 */
export interface RegisterSelectedFlightRequestDto {
    /**
     * 편도 : ONE_WAY 왕복 : ROUND_TRIP
     * @type {string}
     * @memberof RegisterSelectedFlightRequestDto
     */
    itineraryType: RegisterSelectedFlightRequestDtoItineraryType;
    /**
     * 
     * @type {string}
     * @memberof RegisterSelectedFlightRequestDto
     */
    domIntCode: string;
    /**
     * 성인 수
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    adultCount: number;
    /**
     * 
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    childCount: number;
    /**
     * 
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    infantCount: number;
    /**
     * 항공사 코드 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    airCodes: Array<string>;
    /**
     * 항공사 명 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    airNames: Array<string>;
    /**
     * 비행 편명 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    flightNoList: Array<string>;
    /**
     * 출발지 코드 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    depCityCodes: Array<string>;
    /**
     * 출발 도시 명 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    depCityNames: Array<string>;
    /**
     * 출발 일자 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    depDates: Array<string>;
    /**
     * 출발 시간 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    depTimes: Array<string>;
    /**
     * 출발 요일 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    depWeeks: Array<string>;
    /**
     * 도착 도시 코드 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    arrCityCodes: Array<string>;
    /**
     * 도착 도시 명 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    arrCityNames: Array<string>;
    /**
     * 도착 일자 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    arrDates: Array<string>;
    /**
     * 도착 시간 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    arrTimes: Array<string>;
    /**
     * 도착 요일 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    arrWeeks: Array<string>;
    /**
     * 캐빈클래스 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    cabinClasses: Array<string>;
    /**
     * 부킹 클래스 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    bkgClasses: Array<string>;
    /**
     * 승객 유형 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    paxTypes: Array<string>;
    /**
     * 좌석 수 리스트
     * @type {Array<number>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    seatCounts: Array<number>;
    /**
     * 코드쉐어 항공사 코드 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    codeshareCodes?: Array<string>;
    /**
     * 운임 기초 코드 리스트
     * @type {Array<string>}
     * @memberof RegisterSelectedFlightRequestDto
     */
    farebasis: Array<string>;
    /**
     * 성인 가는편 요금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owAdtAmt: number;
    /**
     * 성인 가는편 세금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owAdtTax: number;
    /**
     * 성인 가는편 유류할증료
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owAdtFuel: number;
    /**
     * 성인 가는편 Tasf
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owAdtTasf: number;
    /**
     * 성인 가는편 Q Charge
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owAdtQamt: number;
    /**
     * 성인 오는편 요금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtAdtAmt?: number;
    /**
     * 성인 오는편 세금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtAdtTax?: number;
    /**
     * 성인 오는편 유류할증료
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtAdtFuel?: number;
    /**
     * 성인 오는편 Tasf
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtAdtTasf?: number;
    /**
     * 성인 오는편 Q Charge
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtAdtQamt: number;
    /**
     * 소아 가는편 요금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owChdAmt?: number;
    /**
     * 소아 가는편 세금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owChdTax?: number;
    /**
     * 소아 가는편 유류할증료
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owChdFuel?: number;
    /**
     * 소아 가는편 Tasf
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owChdTasf?: number;
    /**
     * 소아 가는편 Q Charge
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owChdQamt?: number;
    /**
     * 소아 오는편 요금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtChdAmt?: number;
    /**
     * 소아 오는편 세금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtChdTax?: number;
    /**
     * 소아 오는편 유류할증료
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtChdFuel?: number;
    /**
     * 소아 오는편 Tasf
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtChdTasf?: number;
    /**
     * 소아 오는편 Q Charge
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtChdQamt?: number;
    /**
     * 유아 가는편 요금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owInfAmt?: number;
    /**
     * 유아 가는편 세금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owInfTax?: number;
    /**
     * 유아 가는편 유류할증료
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owInfFuel?: number;
    /**
     * 유아 가는편 Tasf
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owInfTasf?: number;
    /**
     * 유아 가는편 Q Charge
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    owInfQamt?: number;
    /**
     * 유아 오는편 요금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtInfAmt?: number;
    /**
     * 유아 오는편 세금
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtInfTax?: number;
    /**
     * 유아 오는편 유류할증료
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtInfFuel?: number;
    /**
     * 유아 오는편 Tasf
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtInfTasf?: number;
    /**
     * 유아 오는편 Q Charge
     * @type {number}
     * @memberof RegisterSelectedFlightRequestDto
     */
    rtInfQamt?: number;
}


/**
 * @export
 */
export const RegisterSelectedFlightRequestDtoItineraryType = {
    ONE_WAY: 'ONE_WAY',
    ROUND_TRIP: 'ROUND_TRIP'
} as const;
export type RegisterSelectedFlightRequestDtoItineraryType = typeof RegisterSelectedFlightRequestDtoItineraryType[keyof typeof RegisterSelectedFlightRequestDtoItineraryType];

/**
 * 
 * @export
 * @interface RegisteredFlightDto
 */
export interface RegisteredFlightDto {
    /**
     * 
     * @type {Array<FareDto>}
     * @memberof RegisteredFlightDto
     */
    fares: Array<FareDto>;
    /**
     * 
     * @type {Array<Avail>}
     * @memberof RegisteredFlightDto
     */
    availList: Array<Avail>;
    /**
     * 
     * @type {Array<Avails>}
     * @memberof RegisteredFlightDto
     */
    avalisList: Array<Avails>;
    /**
     * 
     * @type {Array<FareItinerary>}
     * @memberof RegisteredFlightDto
     */
    fareItineraryList: Array<FareItinerary>;
    /**
     * 소아 귀국일 체크
     * @type {string}
     * @memberof RegisteredFlightDto
     */
    childInBoundDateCheck: string;
    /**
     * 유아 귀국일 체크
     * @type {string}
     * @memberof RegisteredFlightDto
     */
    infantInBoundDateCheck: string;
    /**
     * ID
     * @type {string}
     * @memberof RegisteredFlightDto
     */
    userId: string;
    /**
     * 이름
     * @type {string}
     * @memberof RegisteredFlightDto
     */
    userName: string;
    /**
     * 핸드폰 번호1
     * @type {string}
     * @memberof RegisteredFlightDto
     */
    phoneNo1: string;
    /**
     * 핸드폰 번호2
     * @type {string}
     * @memberof RegisteredFlightDto
     */
    phoneNo2: string;
    /**
     * 핸드폰 번호3
     * @type {string}
     * @memberof RegisteredFlightDto
     */
    phoneNo3: string;
    /**
     * 이메일1
     * @type {string}
     * @memberof RegisteredFlightDto
     */
    email1: string;
    /**
     * 이메일2
     * @type {string}
     * @memberof RegisteredFlightDto
     */
    email2: string;
    /**
     * 이메일3
     * @type {string}
     * @memberof RegisteredFlightDto
     */
    email3: string;
}
/**
 * 
 * @export
 * @interface ReservationAirFareRuleDto
 */
export interface ReservationAirFareRuleDto {
    /**
     * PNR 일련번호
     * @type {string}
     * @memberof ReservationAirFareRuleDto
     */
    pnrSeqNo: string;
    /**
     * 규정 번호
     * @type {string}
     * @memberof ReservationAirFareRuleDto
     */
    ruleNo: string;
    /**
     * 규정 함목 명
     * @type {string}
     * @memberof ReservationAirFareRuleDto
     */
    ruleItemNm: string;
    /**
     * 규정 내용
     * @type {string}
     * @memberof ReservationAirFareRuleDto
     */
    ruleContent: string;
    /**
     * 정렬 순서
     * @type {string}
     * @memberof ReservationAirFareRuleDto
     */
    sortOrdr: string;
    /**
     * 규정 그룹 번호
     * @type {string}
     * @memberof ReservationAirFareRuleDto
     */
    ruleGrpNo: string;
    /**
     * 이관 자료 여부
     * @type {string}
     * @memberof ReservationAirFareRuleDto
     */
    migDataYn: string;
    /**
     * 규정 영문 내용
     * @type {string}
     * @memberof ReservationAirFareRuleDto
     */
    ruleEngContent: string;
}
/**
 * 입금 환불
 * @export
 * @interface ReservationDepositPrint
 */
export interface ReservationDepositPrint {
    /**
     * PNR 일련번호
     * @type {string}
     * @memberof ReservationDepositPrint
     */
    pnrseqno?: string;
    /**
     * 자료 구분
     * @type {string}
     * @memberof ReservationDepositPrint
     */
    dataflag?: string;
    /**
     * 입금 환불 금액
     * @type {string}
     * @memberof ReservationDepositPrint
     */
    depositrefundamt?: string;
    /**
     * 카드 소유주
     * @type {string}
     * @memberof ReservationDepositPrint
     */
    cardowner?: string;
    /**
     * 카드 번호
     * @type {string}
     * @memberof ReservationDepositPrint
     */
    cardno?: string;
    /**
     * 유효 년월
     * @type {string}
     * @memberof ReservationDepositPrint
     */
    validyymm?: string;
    /**
     * 할부 여부
     * @type {string}
     * @memberof ReservationDepositPrint
     */
    instlmtyn?: string;
    /**
     * 할부 기간
     * @type {string}
     * @memberof ReservationDepositPrint
     */
    instlmtperiod?: string;
    /**
     * 카드 승인 번호
     * @type {string}
     * @memberof ReservationDepositPrint
     */
    cardconfmno?: string;
    /**
     * 확인 일시
     * @type {string}
     * @memberof ReservationDepositPrint
     */
    confmdtm?: string;
}
/**
 * 항공예약 기본
 * @export
 * @interface ReservationDetail
 */
export interface ReservationDetail {
    /**
     * PNR 일련번호
     * @type {string}
     * @memberof ReservationDetail
     */
    pnrseqno: string;
    /**
     * 항공사 발권시한
     * @type {string}
     * @memberof ReservationDetail
     */
    airttl: string;
    /**
     * 전체 여정 내용
     * @type {string}
     * @memberof ReservationDetail
     */
    allitincontent: string;
    /**
     * 도착 공항 코드
     * @type {string}
     * @memberof ReservationDetail
     */
    arrairportcd: string;
    /**
     * 도착 공항명
     * @type {string}
     * @memberof ReservationDetail
     */
    arrairportnm: string;
    /**
     * 거래처 코드
     * @type {string}
     * @memberof ReservationDetail
     */
    bcnccd: string;
    /**
     * 취소 일시
     * @type {string}
     * @memberof ReservationDetail
     */
    canceldtm?: string;
    /**
     * 취소 여부
     * @type {string}
     * @memberof ReservationDetail
     */
    cancelyn: string;
    /**
     * 예약자 이메일
     * @type {string}
     * @memberof ReservationDetail
     */
    email: string;
    /**
     * 요금 확정 여부
     * @type {string}
     * @memberof ReservationDetail
     */
    fareconfmyn: string;
    /**
     * 발권 일자
     * @type {string}
     * @memberof ReservationDetail
     */
    issuedate?: string;
    /**
     * 발권 상태 코드
     * @type {string}
     * @memberof ReservationDetail
     */
    issuestatuscd?: string;
    /**
     * 예약자 휴대폰 번호
     * @type {string}
     * @memberof ReservationDetail
     */
    mphoneno: string;
    /**
     * 결제 상태 코드 (BEFORE_PAYMENT: 결제 요청 전, COMPLETED: 결제 요청)
     * @type {string}
     * @memberof ReservationDetail
     */
    paystatuscd: ReservationDetailPaystatuscd;
    /**
     * 결제 시한
     * @type {string}
     * @memberof ReservationDetail
     */
    paytl: string;
    /**
     * PNR 좌석 상태 코드 (CONFIRMED: 확정, WAITING: 대기, UNAVAILABLE: 불가, 취소)
     * @type {string}
     * @memberof ReservationDetail
     */
    pnrseatstatuscd: ReservationDetailPnrseatstatuscd;
    /**
     * 예약 번호
     * @type {string}
     * @memberof ReservationDetail
     */
    rsvno: string;
    /**
     * 예약 상태 코드 (AUTO_CANCELED: 예약 취소(자동), WAITING: 예약 대기, COMPLETED: 예약 완료, PAYMENT_REQUESTED: 결제 요청, TICKET_ISSUED: 발권 완료, CANCELED: 예약 취소, UNPAID_TICKET_ISSUED: 미결제 발권 완료)
     * @type {string}
     * @memberof ReservationDetail
     */
    rsvstatuscd: ReservationDetailRsvstatuscd;
    /**
     * 예약 사용자 ID
     * @type {string}
     * @memberof ReservationDetail
     */
    rsvusrid: string;
    /**
     * 예약 사용자명
     * @type {string}
     * @memberof ReservationDetail
     */
    rsvusrnm: string;
    /**
     * 판매 총 금액
     * @type {string}
     * @memberof ReservationDetail
     */
    saletotamt: string;
    /**
     * STOCK 항공사 코드
     * @type {string}
     * @memberof ReservationDetail
     */
    stockaircd: string;
    /**
     * 결제 일시
     * @type {string}
     * @memberof ReservationDetail
     */
    paydtm?: string;
    /**
     * 발권 상태 명
     * @type {string}
     * @memberof ReservationDetail
     */
    issuestatusnm: string;
    /**
     * 결제 상태 명
     * @type {string}
     * @memberof ReservationDetail
     */
    paystatusnm: string;
    /**
     * STOCK 항공사 명
     * @type {string}
     * @memberof ReservationDetail
     */
    stockairnm?: string;
    /**
     * 결제 요일
     * @type {string}
     * @memberof ReservationDetail
     */
    paytlweek: string;
    /**
     * 취소 요일
     * @type {string}
     * @memberof ReservationDetail
     */
    canceldtmweek: string;
    /**
     * 요금 확정 여부 (한글)
     * @type {string}
     * @memberof ReservationDetail
     */
    fareconfmnm: string;
    /**
     * PNR 좌석 상태 명
     * @type {string}
     * @memberof ReservationDetail
     */
    pnrseatstatusnm: string;
    /**
     * 예약 상태 명
     * @type {string}
     * @memberof ReservationDetail
     */
    rsvstatusnm: string;
}


/**
 * @export
 */
export const ReservationDetailPaystatuscd = {
    BEFORE_PAYMENT: 'BEFORE_PAYMENT',
    COMPLETED: 'COMPLETED'
} as const;
export type ReservationDetailPaystatuscd = typeof ReservationDetailPaystatuscd[keyof typeof ReservationDetailPaystatuscd];

/**
 * @export
 */
export const ReservationDetailPnrseatstatuscd = {
    UNKNOWN: 'UNKNOWN',
    CONFIRMED: 'CONFIRMED',
    WAITING: 'WAITING',
    UNAVAILABLE: 'UNAVAILABLE'
} as const;
export type ReservationDetailPnrseatstatuscd = typeof ReservationDetailPnrseatstatuscd[keyof typeof ReservationDetailPnrseatstatuscd];

/**
 * @export
 */
export const ReservationDetailRsvstatuscd = {
    UNKNOWN: 'UNKNOWN',
    AUTO_CANCELED: 'AUTO_CANCELED',
    WAITING: 'WAITING',
    COMPLETED: 'COMPLETED',
    PAYMENT_REQUESTED: 'PAYMENT_REQUESTED',
    TICKET_ISSUED: 'TICKET_ISSUED',
    CANCELED: 'CANCELED',
    UNPAID_TICKET_ISSUED: 'UNPAID_TICKET_ISSUED'
} as const;
export type ReservationDetailRsvstatuscd = typeof ReservationDetailRsvstatuscd[keyof typeof ReservationDetailRsvstatuscd];

/**
 * 
 * @export
 * @interface ReservationDto
 */
export interface ReservationDto {
    /**
     * PNR 일련번호
     * @type {string}
     * @memberof ReservationDto
     */
    pnrSeqno: string;
    /**
     * 예약번호
     * @type {string}
     * @memberof ReservationDto
     */
    reservationNo: string;
    /**
     * 알파 PNR 번호
     * @type {string}
     * @memberof ReservationDto
     */
    alphaPnrNo: string;
    /**
     * 국내선 국제선 구분(D: 국내선, I: 국제선)
     * @type {string}
     * @memberof ReservationDto
     */
    diFlag: string;
    /**
     * 여정유형(편도, 왕복)
     * @type {string}
     * @memberof ReservationDto
     */
    itineraryType: string;
    /**
     * 목적 도시 코드
     * @type {string}
     * @memberof ReservationDto
     */
    purposeCode: string;
    /**
     * 항공사 명
     * @type {string}
     * @memberof ReservationDto
     */
    airline: string;
    /**
     * 항공 여정
     * @type {string}
     * @memberof ReservationDto
     */
    itineraryContent?: string;
    /**
     * 여정 수
     * @type {string}
     * @memberof ReservationDto
     */
    itineraryCount: string;
    /**
     * 출발 공항 코드
     * @type {string}
     * @memberof ReservationDto
     */
    depCode: string;
    /**
     * 도착 공항 코드
     * @type {string}
     * @memberof ReservationDto
     */
    arrCode: string;
    /**
     * 예약 일시
     * @type {string}
     * @memberof ReservationDto
     */
    reservationDate: string;
    /**
     * 출발 일시
     * @type {string}
     * @memberof ReservationDto
     */
    depDate: string;
    /**
     * 도착 일시
     * @type {string}
     * @memberof ReservationDto
     */
    arrDate: string;
    /**
     * 총 인원 수
     * @type {string}
     * @memberof ReservationDto
     */
    totalCount: string;
    /**
     * 판매 항공사 코드
     * @type {string}
     * @memberof ReservationDto
     */
    saleAirCode: string;
    /**
     * 캐빈 좌석 등급
     * @type {string}
     * @memberof ReservationDto
     */
    cabinClass: string;
    /**
     * 예약 좌석 등급
     * @type {string}
     * @memberof ReservationDto
     */
    reservationClass: string;
    /**
     * 금액
     * @type {string}
     * @memberof ReservationDto
     */
    price: string;
    /**
     * 예약 상태 코드 (WAITING: 예약 대기, COMPLETED: 예약 완료, PAYMENT_REQUESTED: 결제 요청, TICKET_ISSUED: 발권 완료, CANCELED: 예약 취소)
     * @type {string}
     * @memberof ReservationDto
     */
    reservationStatus: ReservationDtoReservationStatus;
    /**
     * 환불 상태 (환불신청중, 환불완료, 환불진행중, 수수료 결제)
     * @type {string}
     * @memberof ReservationDto
     */
    refundStatus: string;
    /**
     * PNR 좌석 상태 코드 (CONFIRMED: 확정, WAITING: 대기, UNAVAILABLE: 불가, 취소)
     * @type {string}
     * @memberof ReservationDto
     */
    seatStatus: ReservationDtoSeatStatus;
    /**
     * 요금 확정 여부
     * @type {string}
     * @memberof ReservationDto
     */
    fareConfirmFlag: string;
    /**
     * 결제 시한
     * @type {string}
     * @memberof ReservationDto
     */
    paymentDeadline: string;
    /**
     * 출발 도시 코드
     * @type {string}
     * @memberof ReservationDto
     */
    depCityCode: string;
    /**
     * 출발 공항 명
     * @type {string}
     * @memberof ReservationDto
     */
    depName: string;
    /**
     * 도착 공항 명
     * @type {string}
     * @memberof ReservationDto
     */
    arrName: string;
    /**
     * 좌석등급 명(일반석, 할인석, 특가석)
     * @type {string}
     * @memberof ReservationDto
     */
    cabinClassName: string;
}


/**
 * @export
 */
export const ReservationDtoReservationStatus = {
    UNKNOWN: 'UNKNOWN',
    AUTO_CANCELED: 'AUTO_CANCELED',
    WAITING: 'WAITING',
    COMPLETED: 'COMPLETED',
    PAYMENT_REQUESTED: 'PAYMENT_REQUESTED',
    TICKET_ISSUED: 'TICKET_ISSUED',
    CANCELED: 'CANCELED',
    UNPAID_TICKET_ISSUED: 'UNPAID_TICKET_ISSUED'
} as const;
export type ReservationDtoReservationStatus = typeof ReservationDtoReservationStatus[keyof typeof ReservationDtoReservationStatus];

/**
 * @export
 */
export const ReservationDtoSeatStatus = {
    UNKNOWN: 'UNKNOWN',
    CONFIRMED: 'CONFIRMED',
    WAITING: 'WAITING',
    UNAVAILABLE: 'UNAVAILABLE'
} as const;
export type ReservationDtoSeatStatus = typeof ReservationDtoSeatStatus[keyof typeof ReservationDtoSeatStatus];

/**
 * 
 * @export
 * @interface ResponseAirFareRulesDto
 */
export interface ResponseAirFareRulesDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseAirFareRulesDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAirFareRulesDto
     */
    message: string;
    /**
     * 
     * @type {AirFareRulesDto}
     * @memberof ResponseAirFareRulesDto
     */
    data?: AirFareRulesDto;
}
/**
 * 
 * @export
 * @interface ResponseAirPaxDiscountsDto
 */
export interface ResponseAirPaxDiscountsDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseAirPaxDiscountsDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAirPaxDiscountsDto
     */
    message: string;
    /**
     * 
     * @type {AirPaxDiscountsDto}
     * @memberof ResponseAirPaxDiscountsDto
     */
    data?: AirPaxDiscountsDto;
}
/**
 * 
 * @export
 * @interface ResponseBoardsDto
 */
export interface ResponseBoardsDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseBoardsDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseBoardsDto
     */
    message: string;
    /**
     * 
     * @type {BoardsDto}
     * @memberof ResponseBoardsDto
     */
    data?: BoardsDto;
}
/**
 * 
 * @export
 * @interface ResponseCompleteReservationResponse
 */
export interface ResponseCompleteReservationResponse {
    /**
     * 
     * @type {string}
     * @memberof ResponseCompleteReservationResponse
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompleteReservationResponse
     */
    message: string;
    /**
     * 
     * @type {CompleteReservationResponse}
     * @memberof ResponseCompleteReservationResponse
     */
    data?: CompleteReservationResponse;
}
/**
 * 
 * @export
 * @interface ResponseCreateSessionResponse
 */
export interface ResponseCreateSessionResponse {
    /**
     * 
     * @type {string}
     * @memberof ResponseCreateSessionResponse
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCreateSessionResponse
     */
    message: string;
    /**
     * 
     * @type {CreateSessionResponse}
     * @memberof ResponseCreateSessionResponse
     */
    data?: CreateSessionResponse;
}
/**
 * 
 * @export
 * @interface ResponseDomesticScheduleGetResponseDto
 */
export interface ResponseDomesticScheduleGetResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseDomesticScheduleGetResponseDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseDomesticScheduleGetResponseDto
     */
    message: string;
    /**
     * 
     * @type {DomesticScheduleGetResponseDto}
     * @memberof ResponseDomesticScheduleGetResponseDto
     */
    data?: DomesticScheduleGetResponseDto;
}
/**
 * 
 * @export
 * @interface ResponseGetMemberReservationsResponse
 */
export interface ResponseGetMemberReservationsResponse {
    /**
     * 
     * @type {string}
     * @memberof ResponseGetMemberReservationsResponse
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseGetMemberReservationsResponse
     */
    message: string;
    /**
     * 
     * @type {GetMemberReservationsResponse}
     * @memberof ResponseGetMemberReservationsResponse
     */
    data?: GetMemberReservationsResponse;
}
/**
 * 
 * @export
 * @interface ResponseHolidaysGetResponseDto
 */
export interface ResponseHolidaysGetResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseHolidaysGetResponseDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseHolidaysGetResponseDto
     */
    message: string;
    /**
     * 
     * @type {HolidaysGetResponseDto}
     * @memberof ResponseHolidaysGetResponseDto
     */
    data?: HolidaysGetResponseDto;
}
/**
 * 
 * @export
 * @interface ResponseInvoiceDto
 */
export interface ResponseInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseInvoiceDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseInvoiceDto
     */
    message: string;
    /**
     * 
     * @type {InvoiceDto}
     * @memberof ResponseInvoiceDto
     */
    data?: InvoiceDto;
}
/**
 * 
 * @export
 * @interface ResponseListAreaDto
 */
export interface ResponseListAreaDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListAreaDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListAreaDto
     */
    message: string;
    /**
     * 
     * @type {Array<AreaDto>}
     * @memberof ResponseListAreaDto
     */
    data?: Array<AreaDto>;
}
/**
 * 
 * @export
 * @interface ResponseListBannerDto
 */
export interface ResponseListBannerDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListBannerDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListBannerDto
     */
    message: string;
    /**
     * 
     * @type {Array<BannerDto>}
     * @memberof ResponseListBannerDto
     */
    data?: Array<BannerDto>;
}
/**
 * 
 * @export
 * @interface ResponseListBoardDetailDto
 */
export interface ResponseListBoardDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListBoardDetailDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListBoardDetailDto
     */
    message: string;
    /**
     * 
     * @type {Array<BoardDetailDto>}
     * @memberof ResponseListBoardDetailDto
     */
    data?: Array<BoardDetailDto>;
}
/**
 * 
 * @export
 * @interface ResponseListCancellationResultDto
 */
export interface ResponseListCancellationResultDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListCancellationResultDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListCancellationResultDto
     */
    message: string;
    /**
     * 
     * @type {Array<CancellationResultDto>}
     * @memberof ResponseListCancellationResultDto
     */
    data?: Array<CancellationResultDto>;
}
/**
 * 
 * @export
 * @interface ResponseListCounselDto
 */
export interface ResponseListCounselDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListCounselDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListCounselDto
     */
    message: string;
    /**
     * 
     * @type {Array<CounselDto>}
     * @memberof ResponseListCounselDto
     */
    data?: Array<CounselDto>;
}
/**
 * 
 * @export
 * @interface ResponseListDeletePassengerInfoResponseDto
 */
export interface ResponseListDeletePassengerInfoResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListDeletePassengerInfoResponseDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListDeletePassengerInfoResponseDto
     */
    message: string;
    /**
     * 
     * @type {Array<DeletePassengerInfoResponseDto>}
     * @memberof ResponseListDeletePassengerInfoResponseDto
     */
    data?: Array<DeletePassengerInfoResponseDto>;
}
/**
 * 
 * @export
 * @interface ResponseListFundamentalCodeDto
 */
export interface ResponseListFundamentalCodeDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListFundamentalCodeDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListFundamentalCodeDto
     */
    message: string;
    /**
     * 
     * @type {Array<FundamentalCodeDto>}
     * @memberof ResponseListFundamentalCodeDto
     */
    data?: Array<FundamentalCodeDto>;
}
/**
 * 
 * @export
 * @interface ResponseListGeneralRuleDto
 */
export interface ResponseListGeneralRuleDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListGeneralRuleDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListGeneralRuleDto
     */
    message: string;
    /**
     * 
     * @type {Array<GeneralRuleDto>}
     * @memberof ResponseListGeneralRuleDto
     */
    data?: Array<GeneralRuleDto>;
}
/**
 * 
 * @export
 * @interface ResponseListGuideMessageDto
 */
export interface ResponseListGuideMessageDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListGuideMessageDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListGuideMessageDto
     */
    message: string;
    /**
     * 
     * @type {Array<GuideMessageDto>}
     * @memberof ResponseListGuideMessageDto
     */
    data?: Array<GuideMessageDto>;
}
/**
 * 
 * @export
 * @interface ResponseListNationDto
 */
export interface ResponseListNationDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListNationDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListNationDto
     */
    message: string;
    /**
     * 
     * @type {Array<NationDto>}
     * @memberof ResponseListNationDto
     */
    data?: Array<NationDto>;
}
/**
 * 
 * @export
 * @interface ResponseListNaverPayResponseDto
 */
export interface ResponseListNaverPayResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListNaverPayResponseDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListNaverPayResponseDto
     */
    message: string;
    /**
     * 
     * @type {Array<NaverPayResponseDto>}
     * @memberof ResponseListNaverPayResponseDto
     */
    data?: Array<NaverPayResponseDto>;
}
/**
 * 
 * @export
 * @interface ResponseListPassengerInfoDto
 */
export interface ResponseListPassengerInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListPassengerInfoDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListPassengerInfoDto
     */
    message: string;
    /**
     * 
     * @type {Array<PassengerInfoDto>}
     * @memberof ResponseListPassengerInfoDto
     */
    data?: Array<PassengerInfoDto>;
}
/**
 * 
 * @export
 * @interface ResponseListPaymentDetailDto
 */
export interface ResponseListPaymentDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListPaymentDetailDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListPaymentDetailDto
     */
    message: string;
    /**
     * 
     * @type {Array<PaymentDetailDto>}
     * @memberof ResponseListPaymentDetailDto
     */
    data?: Array<PaymentDetailDto>;
}
/**
 * 
 * @export
 * @interface ResponseListPaymentDto
 */
export interface ResponseListPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListPaymentDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListPaymentDto
     */
    message: string;
    /**
     * 
     * @type {Array<PaymentDto>}
     * @memberof ResponseListPaymentDto
     */
    data?: Array<PaymentDto>;
}
/**
 * 
 * @export
 * @interface ResponseListReservationAirFareRuleDto
 */
export interface ResponseListReservationAirFareRuleDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListReservationAirFareRuleDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListReservationAirFareRuleDto
     */
    message: string;
    /**
     * 
     * @type {Array<ReservationAirFareRuleDto>}
     * @memberof ResponseListReservationAirFareRuleDto
     */
    data?: Array<ReservationAirFareRuleDto>;
}
/**
 * 
 * @export
 * @interface ResponseListReservationDto
 */
export interface ResponseListReservationDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListReservationDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListReservationDto
     */
    message: string;
    /**
     * 
     * @type {Array<ReservationDto>}
     * @memberof ResponseListReservationDto
     */
    data?: Array<ReservationDto>;
}
/**
 * 
 * @export
 * @interface ResponseListRoundTripReservationDetailDto
 */
export interface ResponseListRoundTripReservationDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseListRoundTripReservationDetailDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseListRoundTripReservationDetailDto
     */
    message: string;
    /**
     * 
     * @type {Array<RoundTripReservationDetailDto>}
     * @memberof ResponseListRoundTripReservationDetailDto
     */
    data?: Array<RoundTripReservationDetailDto>;
}
/**
 * 
 * @export
 * @interface ResponseOneWayReservationDetailDto
 */
export interface ResponseOneWayReservationDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseOneWayReservationDetailDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseOneWayReservationDetailDto
     */
    message: string;
    /**
     * 
     * @type {OneWayReservationDetailDto}
     * @memberof ResponseOneWayReservationDetailDto
     */
    data?: OneWayReservationDetailDto;
}
/**
 * 
 * @export
 * @interface ResponseRegisterCounselResult
 */
export interface ResponseRegisterCounselResult {
    /**
     * 
     * @type {string}
     * @memberof ResponseRegisterCounselResult
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseRegisterCounselResult
     */
    message: string;
    /**
     * 
     * @type {RegisterCounselResult}
     * @memberof ResponseRegisterCounselResult
     */
    data?: RegisterCounselResult;
}
/**
 * 
 * @export
 * @interface ResponseRegisteredFlightDto
 */
export interface ResponseRegisteredFlightDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseRegisteredFlightDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseRegisteredFlightDto
     */
    message: string;
    /**
     * 
     * @type {RegisteredFlightDto}
     * @memberof ResponseRegisteredFlightDto
     */
    data?: RegisteredFlightDto;
}
/**
 * 
 * @export
 * @interface ResponseResult
 */
export interface ResponseResult {
    /**
     * 
     * @type {string}
     * @memberof ResponseResult
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseResult
     */
    message: string;
    /**
     * 
     * @type {Result}
     * @memberof ResponseResult
     */
    data?: Result;
}
/**
 * 
 * @export
 * @interface ResponseString
 */
export interface ResponseString {
    /**
     * 
     * @type {string}
     * @memberof ResponseString
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseString
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseString
     */
    data?: string;
}
/**
 * 
 * @export
 * @interface ResponseUnit
 */
export interface ResponseUnit {
    /**
     * 
     * @type {string}
     * @memberof ResponseUnit
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnit
     */
    message: string;
    /**
     * 
     * @type {object}
     * @memberof ResponseUnit
     */
    data?: object;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {boolean}
     * @memberof Result
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    message: string;
}
/**
 * 
 * @export
 * @interface RoundTripReservationDetailDto
 */
export interface RoundTripReservationDetailDto {
    /**
     * 
     * @type {ReservationDetail}
     * @memberof RoundTripReservationDetailDto
     */
    reservationDetail: ReservationDetail;
    /**
     * 승객 결제 정보
     * @type {Array<FarePax>}
     * @memberof RoundTripReservationDetailDto
     */
    farePaxList: Array<FarePax>;
    /**
     * 
     * @type {Array<Seg>}
     * @memberof RoundTripReservationDetailDto
     */
    segList: Array<Seg>;
    /**
     * 결제 전 동의 사항
     * @type {string}
     * @memberof RoundTripReservationDetailDto
     */
    payAgreeInstruction: string;
    /**
     * 결제 요청 안내 문구(필요시 사용)
     * @type {string}
     * @memberof RoundTripReservationDetailDto
     */
    payInformation: string;
    /**
     * 결제 시간 제한 초과 여부
     * @type {string}
     * @memberof RoundTripReservationDetailDto
     */
    isPayTimeLimitOver: string;
}
/**
 * 
 * @export
 * @interface Rule
 */
export interface Rule {
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    ruleName: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    ruleContent: string;
}
/**
 * 
 * @export
 * @interface Rules
 */
export interface Rules {
    /**
     * 
     * @type {string}
     * @memberof Rules
     */
    airCode: string;
    /**
     * 
     * @type {Array<Rule>}
     * @memberof Rules
     */
    rules: Array<Rule>;
}
/**
 * 오는편 스케줄 목록
 * @export
 * @interface ScheduleDto
 */
export interface ScheduleDto {
    /**
     * 편도 : ONE_WAY 왕복 : ROUND_TRIP
     * @type {string}
     * @memberof ScheduleDto
     */
    itineraryForm: ScheduleDtoItineraryForm;
    /**
     * 운임 번호
     * @type {string}
     * @memberof ScheduleDto
     */
    fareNo: string;
    /**
     * 항공사 코드
     * @type {string}
     * @memberof ScheduleDto
     */
    airCode: string;
    /**
     * 항공사 명
     * @type {string}
     * @memberof ScheduleDto
     */
    airName: string;
    /**
     * 좌석 등급 : A(전체), Y(일반석), V(할인석), S(특가석), C(비즈니스석)
     * @type {string}
     * @memberof ScheduleDto
     */
    cabinClass: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    displayAmount: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    adultAmount: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    adultTicketAmount: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    adultTax: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    adultFuelSurcharge: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    adultTasf: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    adultCount: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    childAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    childTicketAmount: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    childTax?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    childFuelSurcharge?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    childTasf?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    childCount?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    infantAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    infantTicketAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    infantTax?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    infantFuelSurcharge?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    infantTasf?: string;
    /**
     * 유아 수
     * @type {string}
     * @memberof ScheduleDto
     */
    infantCount?: string;
    /**
     * 탑승객 타입 코드
     * @type {string}
     * @memberof ScheduleDto
     */
    paxType?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    paxTypeNm?: string;
    /**
     * 조회 타입 
     * @type {string}
     * @memberof ScheduleDto
     */
    fareType?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    itinNo: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    availNo: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    flightNo: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    depCity: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    depCityName: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    depDate: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    depTime: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    depWeek: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    arrCity: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    arrCityName: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    arrDate: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    arrTime: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    arrWeek: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    seatClass: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    seatCount: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    codeShare?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    codeShareName?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    fareBasis: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    flightTime: string;
}


/**
 * @export
 */
export const ScheduleDtoItineraryForm = {
    ONE_WAY: 'ONE_WAY',
    ROUND_TRIP: 'ROUND_TRIP'
} as const;
export type ScheduleDtoItineraryForm = typeof ScheduleDtoItineraryForm[keyof typeof ScheduleDtoItineraryForm];

/**
 * 
 * @export
 * @interface Seg
 */
export interface Seg {
    /**
     * 여정 번호
     * @type {string}
     * @memberof Seg
     */
    itinno: string;
    /**
     * 도착 공항 코드
     * @type {string}
     * @memberof Seg
     */
    arrairportcd: string;
    /**
     * 도착 도시 코드
     * @type {string}
     * @memberof Seg
     */
    arrcitycd: string;
    /**
     * 도착 일자
     * @type {string}
     * @memberof Seg
     */
    arrdate: string;
    /**
     * 도착 시간
     * @type {string}
     * @memberof Seg
     */
    arrtm: string;
    /**
     * 도착 요일
     * @type {string}
     * @memberof Seg
     */
    arrwkday: string;
    /**
     * 캐빈 좌석 등급
     * @type {string}
     * @memberof Seg
     */
    cabinseatgrad: string;
    /**
     * 코드쉐어 내용
     * @type {string}
     * @memberof Seg
     */
    cdsharecontent: string;
    /**
     * 출발 공항 코드
     * @type {string}
     * @memberof Seg
     */
    depairportcd: string;
    /**
     * 출발 도시 코드
     * @type {string}
     * @memberof Seg
     */
    depcitycd: string;
    /**
     * 출발 일자
     * @type {string}
     * @memberof Seg
     */
    depdate: string;
    /**
     * 출발 시간
     * @type {string}
     * @memberof Seg
     */
    deptm: string;
    /**
     * 출발 요일
     * @type {string}
     * @memberof Seg
     */
    depwkday: string;
    /**
     * 비행편명
     * @type {string}
     * @memberof Seg
     */
    fltno: string;
    /**
     * 판매 항공사 코드
     * @type {string}
     * @memberof Seg
     */
    saleaircd: string;
    /**
     * 좌석 수
     * @type {string}
     * @memberof Seg
     */
    seatcnt: string;
    /**
     * 좌석 상태 코드
     * @type {string}
     * @memberof Seg
     */
    seatstatuscd: string;
    /**
     * 출발 도시 명
     * @type {string}
     * @memberof Seg
     */
    depcitynm: string;
    /**
     * 출발 공항 명
     * @type {string}
     * @memberof Seg
     */
    depairportnm: string;
    /**
     * 도착 도시 명
     * @type {string}
     * @memberof Seg
     */
    arrcitynm: string;
    /**
     * 도착 공항 명
     * @type {string}
     * @memberof Seg
     */
    arrairportnm: string;
    /**
     * 항공사 코드
     * @type {string}
     * @memberof Seg
     */
    aircd: string;
    /**
     * 캐빈 좌석 명
     * @type {string}
     * @memberof Seg
     */
    cabinseatgradnm: string;
    /**
     * 세그먼트 좌석 명
     * @type {string}
     * @memberof Seg
     */
    segseatstatusnm: string;
    /**
     * 코드쉐어 항공사 명
     * @type {string}
     * @memberof Seg
     */
    cdsharecontentnm: string;
    /**
     * 판매 항공사 명
     * @type {string}
     * @memberof Seg
     */
    saleairnm: string;
}
/**
 * 
 * @export
 * @interface UpdateContactRequest
 */
export interface UpdateContactRequest {
    /**
     * PNR 일련번호
     * @type {string}
     * @memberof UpdateContactRequest
     */
    prnSeqNo: string;
    /**
     * 핸드폰 번호
     * @type {string}
     * @memberof UpdateContactRequest
     */
    mphoneNo: string;
    /**
     * 이메일
     * @type {string}
     * @memberof UpdateContactRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface UpdatePassengerInfoRequest
 */
export interface UpdatePassengerInfoRequest {
    /**
     * 한글 성
     * @type {string}
     * @memberof UpdatePassengerInfoRequest
     */
    lastNameKo: string;
    /**
     * 한글 이름
     * @type {string}
     * @memberof UpdatePassengerInfoRequest
     */
    firstNameKo: string;
    /**
     * 영문 성
     * @type {string}
     * @memberof UpdatePassengerInfoRequest
     */
    lastNameEn: string;
    /**
     * 영문 이름
     * @type {string}
     * @memberof UpdatePassengerInfoRequest
     */
    firstNameEn: string;
    /**
     * 성별(남: 1, 여: 2)
     * @type {string}
     * @memberof UpdatePassengerInfoRequest
     */
    sex: string;
    /**
     * 생년월일
     * @type {string}
     * @memberof UpdatePassengerInfoRequest
     */
    birth: string;
    /**
     * 국적 코드
     * @type {string}
     * @memberof UpdatePassengerInfoRequest
     */
    nationCode: string;
}
