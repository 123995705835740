// common.ts
export const IS_CTA_BUTTON_ENABLED = 'isCTAButtonEnabled'
export const FLIGHT_REQUEST = 'flightRequest'

// date.ts
export const FORMATTED_TRAVEL_START_DATE = 'formattedTravelStartDate'

// personnel.ts
export const SUM_COUNT = 'sumCount'
export const SELECTED_PERSONNEL_SEAT_TYPE = 'selectedPersonnelSeatType'
