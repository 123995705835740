import { SearchType } from '@src/views/Search/constants'
import { atom, selector } from 'recoil'
import { travelEndDateState, travelStartDateState } from './date'
import { arrivalState, departureState } from './travelDestination'
import dayjs from 'dayjs'
import { adultCountState, childCountState, infantCountState } from './personnel'
import { storageEffect } from '@src/utils/storageEffect'
import {
  IS_BASIC_FLOW,
  IS_SEARCH_MODAL_OPEN,
  ITINERARY_TYPE,
  SEARCH_TYPE,
} from '@src/store/constants/search/atomsKey'
import {
  IS_BASIC_FLOW_DEFAULT,
  IS_SEARCH_MODAL_OPEN_DEFAULT,
  ITINERARY_TYPE_DEFAULT,
  SEARCH_TYPE_DEFAULT,
} from '@src/store/constants/search/defaultValue'
import {
  FLIGHT_REQUEST,
  IS_CTA_BUTTON_ENABLED,
} from '@src/store/constants/search/selectorKey'

export type ItineraryType = 'ONE_WAY' | 'ROUND_TRIP'

export const isSearchModalOpenState = atom<boolean>({
  key: IS_SEARCH_MODAL_OPEN,
  default: IS_SEARCH_MODAL_OPEN_DEFAULT,
  // effects: [storageEffect(IS_SEARCH_MODAL_OPEN)],  우선 새로고침 후에도, 모달 on/off 상태를 물지 않도록
})

/**
 * 검색 타입 상태
 */
export const searchTypeState = atom<SearchType>({
  key: SEARCH_TYPE,
  default: SEARCH_TYPE_DEFAULT,
  effects: [storageEffect('searchType', 'session')],
})

/**
 * 기본 플로우 인지 Boolean
 */
export const isBasicFlowState = atom<boolean>({
  key: IS_BASIC_FLOW,
  default: IS_BASIC_FLOW_DEFAULT,
  effects: [storageEffect('isBasicFlow', 'session')],
})

export const itineraryTypeState = atom<ItineraryType>({
  key: ITINERARY_TYPE,
  default: ITINERARY_TYPE_DEFAULT,
  effects: [storageEffect('itineraryType', 'session')],
})

export const isCTAButtonEnabledState = selector<boolean>({
  key: IS_CTA_BUTTON_ENABLED,
  get: ({ get }) => {
    const itineraryType = get(itineraryTypeState)
    const travelStartDate = get(travelStartDateState)
    const travelEndDate = get(travelEndDateState)
    const departure = get(departureState)
    const arrival = get(arrivalState)

    if (itineraryType === 'ONE_WAY') {
      return Boolean(travelStartDate && departure.code && arrival.code)
    }

    return Boolean(
      travelStartDate && travelEndDate && departure.code && arrival.code
    )
  },
})

export const flightRequestState = selector({
  key: FLIGHT_REQUEST,
  get: ({ get }) => {
    const travelStart = get(travelStartDateState)
    const travelEnd = get(travelEndDateState)

    return {
      itineraryType: get(itineraryTypeState),
      searchType: 'FARE',
      depCityCodeOutBound: get(departureState).code,
      arrCityCodeOutBound: get(arrivalState).code,
      depDateOutBound: travelStart
        ? dayjs(travelStart).format('YYYYMMDD')
        : dayjs().format('YYYYMMDD'),
      depDateInBound: travelEnd ? dayjs(travelEnd).format('YYYYMMDD') : '',
      cabinClass: 'A',
      adultCount: get(adultCountState),
      childCount: get(childCountState),
      infantCount: get(infantCountState),
    }
  },
})
