import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion'
import { type ReactNode, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Portal } from '@src/components/ui/portal'

import { NavProps, Navigation, UnitStyle } from '@socar-inc/socar-design-system'

import { useEffectOnce } from '@src/hooks'
import {
  BottomSheetProvider,
  BottomSheetProviderProps,
  useBottomSheetActions,
  useBottomSheetSize,
  useBottomSheetState,
} from '.'

export const ACTION_BAR_HEIGHT = 56

interface BasicBottomSheetProps {
  children: ReactNode
  className?: string
}

interface BottomSheetProps extends BasicBottomSheetProps {
  /**
   * BottomSheet Content 영역의 최대 높이
   * @default 9999
   * @description
   * 최대 높이를 설정하면, 컨텐츠가 최대 높이를 넘어갈 경우 스크롤이 생깁니다.
   * BottomSheet의 최대 높이는 100vh를 넘을 수 없습니다.
   */
  maxHeight?: number
  /**
   * BottomSheet가 열릴 때, 상단으로부터의 간격. 해당 간격만큼 상단으로부터 떨어져서 열립니다.
   * @default 0
   */
  spacingFromTop?: number
  /**
   * BottomSheet의 핸들 영역 표시 여부
   * @default true
   */
  hasHandle?: boolean
  /**
   * BottomSheet의 기본 높이를 full height로 설정할지 여부
   * @default false
   */
  isFullHeight?: boolean
  /**
   * BottomSheet를 드래그 가능하게 할지 여부
   * @default true
   */
  draggable?: boolean
  /**
   * BottomSheet를 닫을 수 있는지 여부
   * @default true
   */
  closable?: boolean
  /**
   * Route 변경 시 BottomSheet 닫기
   * @default true
   */
  shouldCloseOnRouteChange?: boolean
  /**
   * framer-motion의 motion.div props
   */
  motionProps?: HTMLMotionProps<'div'>
  /**
   * Portal에 dim을 추가할지 여부
   */
  hasDim?: boolean
  /**
   * BottomSheet의 초기 open 상태
   */
  initialOpen?: boolean
}

export const BottomSheetPortal = ({
  children,
  className,
  maxHeight = 9999,
  spacingFromTop = 0,
  hasHandle = true,
  isFullHeight = false,
  draggable = true,
  closable = true,
  shouldCloseOnRouteChange = true,
  motionProps,
  hasDim = true,
  initialOpen = false,
}: BottomSheetProps) => {
  const bottomSheetRef = useRef<HTMLDivElement | null>(null)
  const [dragStartY, setDragStartY] = useState(0)

  const { setBottomSheetSize } = useBottomSheetSize({
    maxHeight,
    spacingFromTop,
    bottomSheetRef,
    isFullHeight,
  })

  const { isOpen } = useBottomSheetState()
  const { onOpen, onClose } = useBottomSheetActions()

  useEffectOnce(() => {
    if (initialOpen) {
      setTimeout(() => {
        onOpen()
      }, 500)
    }
  })

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal
          type="bottom-sheet"
          isOpen={isOpen}
          onClose={onClose}
          mainWrapperRef={bottomSheetRef}
          closable={closable}
          shouldCloseOnRouteChange={shouldCloseOnRouteChange}
          hasDim={hasDim}>
          <motion.div
            ref={(el) => {
              setBottomSheetSize(el)
              bottomSheetRef.current = el
            }}
            className={twMerge(
              'tw-fixed tw-bottom-0 tw-left-[--bottom-sheet-container-x] tw-z-[10001] tw-flex tw-w-full tw-min-w-[320px] tw-max-w-[420px] tw-flex-col tw-bg-white tw-shadow-[0_-2px_6px_rgba(40,50,60,0.1)]',
              hasHandle && 'tw-rounded-t-[16px]',
              className
            )}
            initial={{ y: '100%' }}
            animate={{ y: '0%' }}
            exit={{ y: '100%' }}
            transition={{ duration: 0.15, ease: 'easeInOut' }}
            drag={draggable && 'y'}
            dragSnapToOrigin
            dragConstraints={{ top: 0 }}
            dragElastic={0}
            dragDirectionLock
            dragTransition={{ bounceStiffness: 1000, bounceDamping: 100 }}
            onDragStart={(_, { point }) => {
              setDragStartY(point.y)
            }}
            onDragEnd={(_, { point }) => {
              const DRAG_DISTANCE_PX = 100
              if (point.y - dragStartY > DRAG_DISTANCE_PX) {
                onClose()
              }
            }}
            {...motionProps}>
            {hasHandle && <BottomSheetHandle />}
            {children}
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  )
}

interface BottomSheetLayerPortalProps extends BottomSheetProps {
  children: ReactNode
  navProps?: NavProps
}

export const BottomSheetLayerPortal = ({
  children,
  navProps,
  ...portalProps
}: BottomSheetLayerPortalProps) => {
  const { onClose } = useBottomSheetActions()

  return (
    <BottomSheetPortal
      hasHandle={false}
      isFullHeight
      closable={false}
      draggable={false}
      hasDim={false}
      motionProps={{
        initial: { opacity: 0, y: '10%' },
        animate: { opacity: 1, y: '0%' },
        exit: { opacity: 0, y: '10%' },
        transition: { duration: 0.15 },
      }}
      {...portalProps}>
      <Navigation
        pageName=""
        navHeight={ACTION_BAR_HEIGHT}
        style={{
          position: 'sticky',
          left: 'auto',
          width: '100%',
          minWidth: '320px',
          maxWidth: '420px',
          zIndex: 10,
        }}
        leftUnit1={null}
        rightUnit1={{
          unitStyle: UnitStyle.CLOSE,
          clickFunction: onClose,
        }}
        {...navProps}
      />

      {children}
    </BottomSheetPortal>
  )
}

const BottomSheetTrigger = ({ children, className }: BasicBottomSheetProps) => {
  const { onOpen } = useBottomSheetActions()

  return (
    <span onClick={onOpen} className={className}>
      {children}
    </span>
  )
}

/**
 * 핸들 영역
 */
const BottomSheetHandle = () => (
  <div className="tw-flex tw-cursor-grab tw-items-center tw-justify-center tw-py-12">
    <svg width="40" height="4" className="tw-rounded-2 tw-fill-grey030">
      <rect width="100%" height="100%" />
    </svg>
  </div>
)

/**
 * 타이틀 영역
 */
const BottomSheetTitle = ({ children, className }: BasicBottomSheetProps) => (
  <header className={twMerge('header3', className)}>{children}</header>
)

/**
 * 컨텐츠 영역
 */
const BottomSheetContent = ({ children, className }: BasicBottomSheetProps) => {
  const { isOpen } = useBottomSheetState()

  // BottomSheet가 닫힐 때, 최대 높이와 최소 높이를 제거
  useEffect(() => {
    if (isOpen) return
    const removeBottomSheetProperties = () => {
      setTimeout(() => {
        document.documentElement.style.removeProperty(
          '--bottom-sheet-min-height'
        )
      }, 300)
    }

    removeBottomSheetProperties()
    return () => {
      removeBottomSheetProperties()
    }
  }, [isOpen])

  return (
    <main
      id="bottom-sheet-content"
      className={twMerge(
        'tw-relative tw-max-h-[--bottom-sheet-max-height] tw-min-h-[--bottom-sheet-min-height]',
        'tw-overflow-y-auto tw-overscroll-contain',
        className
      )}>
      {children}
    </main>
  )
}

/**
 * 하단 버튼 영역
 */
const BottomSheetFooterButton = ({
  children,
  className,
  hideGradient = false,
}: BasicBottomSheetProps & {
  hideGradient?: boolean
}) => {
  return (
    <>
      <footer className={twMerge('tw-z-[999] tw-w-full', className)}>
        {!hideGradient && (
          <div className="bottom-sheet-cta-gradient tw-bg-transparent -tw-mt-24 tw-h-[24px]" />
        )}
        {children}
      </footer>
    </>
  )
}

const BottomSheetRoot = ({
  children,
  onBeforeOpen,
  onBeforeClose,
}: BottomSheetProviderProps) => (
  <BottomSheetProvider
    onBeforeOpen={onBeforeOpen}
    onBeforeClose={onBeforeClose}>
    {children}
  </BottomSheetProvider>
)

/**
 * BottomSheet 컴포넌트
 */
export const BottomSheet = Object.assign(BottomSheetPortal, {
  Root: BottomSheetRoot,
  Portal: BottomSheetPortal,
  LayerPortal: BottomSheetLayerPortal,
  Trigger: BottomSheetTrigger,
  Title: BottomSheetTitle,
  Content: BottomSheetContent,
  FooterButton: BottomSheetFooterButton,
})
