import { DefaultApi, GetBannersRequest, ResponseListBannerDto } from '@src/api'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

const api = new DefaultApi()

export const useGetBannersQuery = (
  request: GetBannersRequest,
  options?: Omit<UseQueryOptions<ResponseListBannerDto>, 'queryKey'>
) =>
  useQuery({
    queryKey: ['getBanners', request],
    queryFn: () => api.getBanners(request),
    ...options,
  })
