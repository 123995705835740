import { useEffect } from 'react'

import {
  BottomSheet,
  useBottomSheetActions,
} from '@src/components/ui/bottom-sheet'
import { CTAButton } from '@src/components/ui/CTAButton'
import { useSpacingFromTop } from '@src/hooks/useSpacingFromTop'

interface LoginBottomSheetProps {
  isOpen: boolean
  reason?: string
  guestButtonText?: string
  handleMemberLogin: VoidFunction
  handleGuestLogin: VoidFunction
}

const LoginBottomSheet = ({
  isOpen,
  reason = '항공권 예약을 위해',
  guestButtonText = '비회원 예약',
  handleMemberLogin,
  handleGuestLogin,
}: LoginBottomSheetProps) => {
  const spacingFromTop = useSpacingFromTop(20)
  const { onOpen, onClose } = useBottomSheetActions()

  useEffect(() => {
    if (isOpen) {
      onOpen()
    } else {
      onClose()
    }
  }, [isOpen, onClose, onOpen])

  return (
    <>
      <BottomSheet.Portal spacingFromTop={spacingFromTop}>
        <BottomSheet.Title className="tw-flex tw-flex-col tw-gap-y-8 tw-px-16 tw-pb-8 tw-pt-4">
          <h3>
            {reason} 로그인을
            <br />
            진행해 주세요.
          </h3>
        </BottomSheet.Title>
        <BottomSheet.Content className="tw-px-16 tw-pb-8">
          <span className="body1 tw-text-text-secondary">
            쏘카 회원으로 예약하고 다양한 혜택을 누려보세요!
          </span>
        </BottomSheet.Content>
        <BottomSheet.FooterButton className="tw-bg-white tw-px-16 tw-pb-12 tw-pt-24">
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-y-12">
            <CTAButton
              onClick={handleMemberLogin}
              buttonStyle="cta"
              buttonSize="large"
              fontSize="large"
              buttonClassName="tw-w-full">
              간편 로그인하기
            </CTAButton>
            <button
              className="tw-px-6 tw-py-4 tw-text-text-secondary tw-underline tw-underline-offset-4"
              onClick={handleGuestLogin}>
              {guestButtonText}
            </button>
          </div>
        </BottomSheet.FooterButton>
      </BottomSheet.Portal>
    </>
  )
}

export default LoginBottomSheet
