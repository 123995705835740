import {
  QueryClient,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query'

import { PassengerApi, type ResponseListPassengerInfoDto } from '@src/api'

const api = new PassengerApi()

export const useGetPassengers = (
  options?: Omit<UseQueryOptions<ResponseListPassengerInfoDto>, 'queryKey'>
) => {
  return useQuery({
    queryKey: ['passengers'],
    queryFn: () => api.getPassengerInfo({ credentials: 'include' }),
    ...options,
  })
}

export const fetchPassengers = (queryClient: QueryClient) =>
  queryClient.fetchQuery({
    queryKey: ['passengers'],
    queryFn: () => api.getPassengerInfo({ credentials: 'include' }),
  })
