import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

export const PageNameContext = createContext<
  [string, (pageName: string) => void]
>(['', () => {}])

export const PageNameProvider = ({ children }: { children: ReactNode }) => {
  const state = useState('')

  return (
    <PageNameContext.Provider value={state}>
      {children}
    </PageNameContext.Provider>
  )
}

export const usePageTitle = (pageName: string) => {
  const [, setPageName] = useContext(PageNameContext)

  useEffect(() => {
    setPageName(pageName)
  })
}
