import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useId,
  useState,
} from 'react'
import ReactDOM from 'react-dom'

type PortalType =
  | 'modal'
  | 'popover'
  | 'toast'
  | 'layer'
  | 'bottom-sheet'
  | 'loader'

interface PortalProps extends PropsWithChildren {
  type: PortalType
  isOpen: boolean
  onClose: () => void
  /**
   * Portal의 컨텐츠 영역(Dim이 아닌 영역)을 감싸는 Wrapper의 ref
   */
  mainWrapperRef?: MutableRefObject<HTMLDivElement | null>
  /**
   * 툴팁 버튼과 같은 Trigger의 ref
   */
  triggerRef?: MutableRefObject<HTMLElement | null>
  /**
   * Portal이 열려있는 동안 body 스크롤 막기
   * @default false
   */
  scrollable?: boolean
  /**
   * mainWrapper, trigger가 아닌 영역(ex: Dim)을 클릭했을 때 or ESC키 입력 시 Portal 닫기
   * @default true
   */
  closable?: boolean
  /**
   * Dim 표시 여부
   * @default true
   */
  hasDim?: boolean
  /**
   * Route 변경 시 Portal 닫기
   * @default true
   */
  shouldCloseOnRouteChange?: boolean
}

export function Portal({
  children,
  type,
  isOpen,
  onClose,
  mainWrapperRef,
  triggerRef,
  hasDim = true,
  scrollable = false,
  closable = true,
  shouldCloseOnRouteChange = true,
}: PortalProps) {
  const router = useRouter()

  const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(
    null
  )
  const portalId = `portal-${type}__${useId()}`

  /**
   * isOpen 값에 따라 Portal Container 생성 or 제거
   */
  useEffect(() => {
    const createPortal = () => {
      const newPortalContainer = document.createElement('div')
      newPortalContainer.setAttribute('id', portalId)
      document.body.appendChild(newPortalContainer)
      setPortalContainer(newPortalContainer)
    }

    const removePortal = () => {
      const containerDOM = document.getElementById(portalId)
      setPortalContainer(null)
      setTimeout(() => {
        containerDOM?.remove()
      }, 1000)
    }

    isOpen ? createPortal() : removePortal()

    return () => {
      removePortal()
    }
  }, [isOpen, portalId])

  /**
   * mainWrapper, trigger가 아닌 영역(ex: Dim)을 클릭했을 때 Portal 닫기
   */
  useEffect(() => {
    if (!closable) return

    const onScreenClick = ({ target }: MouseEvent | TouchEvent) => {
      if (!(target instanceof Node)) return

      const isMainWrapper = mainWrapperRef?.current?.contains(target)
      const isTrigger = triggerRef?.current?.contains(target)

      if (!isMainWrapper && !isTrigger) {
        onClose()
      }
    }

    window.addEventListener('click', onScreenClick, true)
    window.addEventListener('touchstart', onScreenClick, true)
    return () => {
      window.removeEventListener('click', onScreenClick, true)
      window.removeEventListener('touchstart', onScreenClick, true)
    }
  }, [closable, mainWrapperRef, onClose, triggerRef])

  /**
   * ESC 키 입력 시 Portal 닫기
   */
  useEffect(() => {
    if (!closable) return

    const onEscPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keydown', onEscPress)
    return () => {
      window.removeEventListener('keydown', onEscPress)
    }
  }, [closable, onClose])

  /**
   * Portal이 열려있을 때 body 스크롤 막기
   */
  useEffect(() => {
    if (scrollable) return

    const activateScroll = () => {
      document.body.style.setProperty('overflow', 'visible')
      document.body.style.setProperty('touch-action', 'auto')
    }

    const deactivateScroll = () => {
      document.body.style.setProperty('overflow', 'hidden')
      document.body.style.setProperty('touch-action', 'none')
    }

    isOpen ? deactivateScroll() : activateScroll()

    return () => {
      activateScroll()
    }
  }, [isOpen, scrollable])

  /**
   * Route 변경 시 Portal 닫기
   */
  useEffect(() => {
    if (!shouldCloseOnRouteChange) return
    router.events.on('routeChangeStart', onClose)
    return () => {
      router.events.off('routeChangeStart', onClose)
    }
  }, [onClose, router.events, shouldCloseOnRouteChange])

  if (!portalContainer) return null

  return ReactDOM.createPortal(
    <>
      {hasDim && <Dim />}
      {children}
    </>,
    portalContainer
  )
}

const Dim = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.3 }}
    className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[10000] tw-bg-black tw-bg-opacity-50"
  />
)
