import { EffectCallback, useEffect, useRef } from 'react'

export const useEffectOnce = (effectCallback: EffectCallback) => {
  const calledOnce = useRef(false)

  useEffect(() => {
    if (calledOnce.current) return
    calledOnce.current = true
    effectCallback()
  }, [effectCallback])
}
