import { useCallback, useEffect } from 'react'

interface UseBottomSheetSizeProps {
  bottomSheetRef: React.RefObject<HTMLDivElement>
  maxHeight: number
  spacingFromTop: number
  isFullHeight: boolean
}

export const useBottomSheetSize = ({
  bottomSheetRef,
  maxHeight,
  spacingFromTop,
  isFullHeight,
}: UseBottomSheetSizeProps) => {
  const setBottomSheetSize = useCallback(
    (contentEl: HTMLDivElement | null) => {
      if (!contentEl) return

      const contentNodes = Array.from(contentEl.childNodes)

      // TODO: 논의 필요. 제일 껍데기에 form 태그를 둘 수가 없음.
      // if (!contentNodes.find(({ nodeName }) => nodeName === 'MAIN')) {
      //   throw new Error('<BottomSheet.Content> 컴포넌트로 감싸주세요.')
      // }

      // BottomSheet.Portal의 자식요소 중 BottomSheet.Content를 제외한 요소들의 높이를 구함
      const heightWithoutContent = contentNodes
        .filter(({ nodeName }) => nodeName !== 'MAIN')
        .reduce((acc, cur) => {
          if (!(cur instanceof HTMLElement)) return acc
          return acc + cur.offsetHeight
        }, 0)

      const maxContentHeight = window.innerHeight - heightWithoutContent
      const fullHeight = maxContentHeight - spacingFromTop

      // maxHeight로 설정한 값이 스크린 사이즈를 넘어가면 maxContentHeight값으로 설정
      const contentHeight =
        maxHeight < maxContentHeight ? maxHeight : fullHeight

      document.documentElement.style.setProperty(
        '--bottom-sheet-max-height',
        `${contentHeight}px`
      )

      if (isFullHeight) {
        document.documentElement.style.setProperty(
          '--bottom-sheet-min-height',
          `${fullHeight}px`
        )
      }
    },
    [isFullHeight, maxHeight, spacingFromTop]
  )

  // 화면 사이즈가 변경될 때마다 bottomSheetSize를 다시 계산
  useEffect(() => {
    const handleResize = () => {
      if (!bottomSheetRef.current) return
      setBottomSheetSize(bottomSheetRef.current)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [bottomSheetRef, setBottomSheetSize])

  return { setBottomSheetSize }
}
