import {
  APIApi,
  GetAirFareRules1Request,
  ResponseAirFareRulesDto,
  ResponseAirPaxDiscountsDto,
  ResponseListGeneralRuleDto,
  ResponseListNationDto,
  ResponseRegisteredFlightDto,
} from '@src/api'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

const api = new APIApi()

/**
 * 세션에 등록된 항공편을 가져오는 쿼리
 * availList: 항공편 목록 간소화 정보
 * availsList: 항공편 목록 상세 정보
 * fares: 승객 타입별 요금 정보
 * fareItineraryList: 전체 여정 승객 타입별 요금 정보 (fares.length === fareItineraryList.length 라면 편도, 아니라면 왕복)
 * @param options
 * @returns
 */
export const useGetRegisteredFlightQuery = (
  options?: Omit<UseQueryOptions<ResponseRegisteredFlightDto>, 'queryKey'>
) =>
  useQuery({
    queryKey: ['getRegisteredFlight'],
    queryFn: () =>
      api.getRegisteredFlight({
        credentials: 'include',
      }),
    ...options,
  })

/**
 * 항공 일반 규정을 가져오는 쿼리
 * @param options
 * @returns
 */
export const useGetGeneralRulesQuery = (
  options?: UseQueryOptions<ResponseListGeneralRuleDto>
) =>
  useQuery({
    queryKey: ['getGeneralRules'],
    queryFn: () =>
      api.getGeneralRules({
        credentials: 'include',
      }),
    ...options,
  })

/**
 * 항공 할인 정보를 가져오는 쿼리
 * @param options
 * @returns
 */
export const useGetAirPaxDiscountsQuery = (
  options?: UseQueryOptions<ResponseAirPaxDiscountsDto>
) =>
  useQuery({
    queryKey: ['getAirPaxDiscounts'],
    queryFn: () =>
      api.getAirPaxDiscounts({
        credentials: 'include',
      }),
    ...options,
  })

/**
 * 항공 요금 규정을 가져오는 쿼리
 * @param request
 * @param options
 * @returns
 */
export const useGetAirFareRules1Query = (
  request: GetAirFareRules1Request,
  options?: Omit<UseQueryOptions<ResponseAirFareRulesDto>, 'queryKey'>
) =>
  useQuery({
    queryKey: ['getAirFareRules1', request],
    queryFn: () =>
      api.getAirFareRules1(request, {
        credentials: 'include',
      }),
    ...options,
  })

/**
 * 국가 정보를 가져오는 쿼리
 * @param options
 * @returns
 */
export const useGetNationsQuery = (
  options?: Omit<UseQueryOptions<ResponseListNationDto>, 'queryKey'>
) =>
  useQuery({
    queryKey: ['getNations'],
    queryFn: () => api.getNations(),
    ...options,
  })
