import { BannerDtoPosition } from '@src/api'
import { tv } from 'tailwind-variants'

export const bannerVariant = tv({
  variants: {
    status: {
      [BannerDtoPosition.TOP]: 'tw-w-[327px] tw-h-[96px]',
      [BannerDtoPosition.RIGHT]: 'tw-w-[318px] tw-h-[64px]',
      [BannerDtoPosition.BOTTOM]: '',
      [BannerDtoPosition.LEFT]: '',
    },
  },
})
