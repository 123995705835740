import {
  TRAVEL_END_DATE,
  TRAVEL_START_DATE,
} from '@src/store/constants/search/atomsKey'
import { TRAVEL_DATE_DEFAULT } from '@src/store/constants/search/defaultValue'
import { FORMATTED_TRAVEL_START_DATE } from '@src/store/constants/search/selectorKey'
import { formatDateWithMonthDayAndDayOfWeek } from '@src/utils/date'
import { storageEffect } from '@src/utils/storageEffect'
import { atom, selector } from 'recoil'

export type TravelDate = Date | null

export const travelStartDateState = atom<TravelDate>({
  key: TRAVEL_START_DATE,
  default: TRAVEL_DATE_DEFAULT,
  effects: [storageEffect('travelStartDate', 'session')],
})

export const travelEndDateState = atom<TravelDate>({
  key: TRAVEL_END_DATE,
  default: TRAVEL_DATE_DEFAULT,
  effects: [storageEffect('travelEndDate', 'session')],
})

export const formattedTravelStartDateState = selector<string>({
  key: FORMATTED_TRAVEL_START_DATE,
  get: ({ get }) => {
    const travelStartDate = get(travelStartDateState)
    if (!travelStartDate) return ''

    return formatDateWithMonthDayAndDayOfWeek(travelStartDate)
  },
})

export const formattedTravelEndDateState = selector({
  key: 'formattedTravelEndDate',
  get: ({ get }) => {
    const travelEndDate = get(travelEndDateState)
    if (!travelEndDate) return ''

    return formatDateWithMonthDayAndDayOfWeek(travelEndDate)
  },
})
