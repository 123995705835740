import {
  CloseUnitProps,
  IconUnitProps,
  Navigation,
  TextUnitProps,
  UnitStyle,
} from '@socar-inc/socar-design-system'
import { match, P } from 'ts-pattern'
import { useRouter } from 'next/router'
import { ReactNode, useContext, useLayoutEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { PageNameContext } from './PageNameProvider'
import { Sidebar } from './Sidebar'
import { AnimatePresence } from 'framer-motion'
import { NAVIGATION_HEIGHT } from './constants'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { isSearchModalOpenState } from '@src/store/atoms/search/common'
import { isPassengerEditableState } from '@src/store/atoms/passenger'
import { isRightUnitVisibleState } from '@src/store/atoms/common'

export const DefaultLayout = ({ children }: { children: ReactNode }) => {
  const router = useRouter()
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const [isSearchModalOpen, setIsSearchModalOpen] = useRecoilState(
    isSearchModalOpenState
  )
  const [isRightUnitVisible, setIsRightUnitVisible] = useRecoilState(
    isRightUnitVisibleState
  )
  const setIsPassengerEditable = useSetRecoilState(isPassengerEditableState)

  const [hasHistory, setHasHistory] = useState(false)

  const [pageName] = useContext(PageNameContext)
  const pathname = router.pathname

  const rightUnit1 = match({
    isSearchModalOpen,
    pathname,
    isRightUnitVisible,
  })
    .with(
      { isSearchModalOpen: true },
      (): CloseUnitProps => ({
        unitStyle: UnitStyle.CLOSE,
        clickFunction: () => setIsSearchModalOpen(false),
      })
    )
    .with(
      P.when(
        ({ pathname, isRightUnitVisible }) =>
          pathname === '/passengers' && isRightUnitVisible
      ),
      (): TextUnitProps => ({
        unitStyle: UnitStyle.TEXT,
        text: '편집',
        color: '#0179FF',
        clickFunction: () => {
          setIsPassengerEditable(true)
          setIsRightUnitVisible(false)
        },
      })
    )
    .with(
      { pathname: '/' },
      (): IconUnitProps => ({
        unitStyle: UnitStyle.ICON,
        iconName: 'ic24_menu',
        clickFunction: () => setIsSidebarVisible(true),
      })
    )
    .otherwise(() => null)

  useLayoutEffect(() => {
    setHasHistory(
      typeof window !== 'undefined' ? window.history.length > 1 : false
    )
  }, [])

  return (
    <div>
      <Navigation
        navHeight={NAVIGATION_HEIGHT}
        showPageTitle
        pageName={pageName ?? '항공'}
        leftUnit1={
          hasHistory && !isSearchModalOpen && pathname !== '/'
            ? {
                unitStyle: UnitStyle.BACK,
                clickFunction: () => router.back(),
              }
            : null
        }
        rightUnit1={rightUnit1}
        style={{ maxWidth: 420, minWidth: 'auto', left: 'auto' }}
      />
      <div
        id="default-layout"
        className={twMerge('tw-relative tw-min-h-screen tw-pt-56')}>
        <AnimatePresence>
          {isSidebarVisible && (
            <Sidebar
              isVisible={isSidebarVisible}
              setIsVisible={setIsSidebarVisible}
            />
          )}
        </AnimatePresence>
        {children}
      </div>
    </div>
  )
}
