/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AirFareRulesGetRequest,
  CancelReservationRequest,
  CardPaymentRequest,
  CompleteReservationRequest,
  CreateGuestSessionRequest,
  CreateGuestSessionWithAuthCodeRequest,
  DomesticScheduleGetRequestDto,
  FindGuestReservationRequest,
  GetScheduleFromNaverLandingPageRequest,
  InvoiceEmailRequest,
  NaverPayRequest,
  RegisterCounselRequest,
  RegisterSelectedFlightRequestDto,
  ResponseAirFareRulesDto,
  ResponseAirPaxDiscountsDto,
  ResponseBoardsDto,
  ResponseCompleteReservationResponse,
  ResponseCreateSessionResponse,
  ResponseDomesticScheduleGetResponseDto,
  ResponseGetMemberReservationsResponse,
  ResponseHolidaysGetResponseDto,
  ResponseInvoiceDto,
  ResponseListAreaDto,
  ResponseListBoardDetailDto,
  ResponseListCancellationResultDto,
  ResponseListCounselDto,
  ResponseListFundamentalCodeDto,
  ResponseListGeneralRuleDto,
  ResponseListGuideMessageDto,
  ResponseListNationDto,
  ResponseListNaverPayResponseDto,
  ResponseListPaymentDetailDto,
  ResponseListPaymentDto,
  ResponseListReservationAirFareRuleDto,
  ResponseListReservationDto,
  ResponseListRoundTripReservationDetailDto,
  ResponseOneWayReservationDetailDto,
  ResponseRegisterCounselResult,
  ResponseRegisteredFlightDto,
  ResponseResult,
  ResponseString,
  ResponseUnit,
  UpdateContactRequest,
} from '../models/index';

export interface CancelReservationOperationRequest  {
  cancelReservationRequest: CancelReservationRequest;
}

export interface CompleteReservationOperationRequest  {
  completeReservationRequest: CompleteReservationRequest;
}

export interface CreateGuestSessionOperationRequest  {
  createGuestSessionRequest?: CreateGuestSessionRequest;
}

export interface CreateGuestSessionWithAuthCodeOperationRequest  {
  createGuestSessionWithAuthCodeRequest: CreateGuestSessionWithAuthCodeRequest;
}

export interface CreateMemberSessionRequest  {
  isFromNaver?: boolean;
}

export interface ExecuteCardPaymentRequest  {
  cardPaymentRequest: CardPaymentRequest;
}

export interface ExecuteNaverPayRequest  {
  naverPayRequest: NaverPayRequest;
}

export interface FindGuestReservationOperationRequest  {
  findGuestReservationRequest: FindGuestReservationRequest;
}

export interface GetAirFareRulesRequest  {
  pnrNo: string;
}

export interface GetAirFareRules1Request extends AirFareRulesGetRequest {
}

export interface GetBoardDetailRequest  {
  seqNo: string;
}

export interface GetBoardsRequest  {
  boardType: GetBoardsBoardType;
  searchNum: number;
  pageNum?: number;
}

export interface GetCounselsRequest  {
  pnrNo: string;
}

export interface GetDomesticSchedulesRequest extends DomesticScheduleGetRequestDto {
}

export interface GetFundamentalCodesRequest  {
  masterCode: GetFundamentalCodesMasterCode;
}

export interface GetGuideMessagesRequest  {
  codes: Array<GetGuideMessagesCodes>;
}

export interface GetInvoiceRequest  {
  pnrNo: string;
}

export interface GetMemberReservationsRequest  {
  startDate?: string;
  endDate?: string;
  rsvStatus?: GetMemberReservationsRsvStatus;
  searchQuery?: string;
  pageSize?: number;
  currentPage?: number;
}

export interface GetOneWayReservationDetailRequest  {
  pnrNo: string;
}

export interface GetPaymentDetailsRequest  {
  pnrNo: string;
}

export interface GetRoundTripReservationDetailRequest  {
  outBoundPnrNo: string;
  returnPnrNo: string;
}

export interface GetScheduleFromNaverLandingPageOperationRequest extends GetScheduleFromNaverLandingPageRequest {
}

export interface RegisterCounselOperationRequest  {
  registerCounselRequest: RegisterCounselRequest;
}

export interface RegisterSelectedFlightToSessionRequest  {
  registerSelectedFlightRequestDto: RegisterSelectedFlightRequestDto;
}

export interface SendInvoiceEmailRequest  {
  invoiceEmailRequest: InvoiceEmailRequest;
}

export interface UpdateContactOperationRequest  {
  updateContactRequest: UpdateContactRequest;
}


/**
 * 
 */
export class APIApi extends runtime.BaseAPI {

    /**
     * 예약을 취소합니다.
     * 예약 취소
     */
    async cancelReservationRaw(requestParameters: CancelReservationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListCancellationResultDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reservations/cancel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['cancelReservationRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 예약을 취소합니다.
     * 예약 취소
     */
    async cancelReservation(requestParameters: CancelReservationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListCancellationResultDto> {
        const response = await this.cancelReservationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 예약을 완료합니다.
     * 예약 완료
     */
    async completeReservationRaw(requestParameters: CompleteReservationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseCompleteReservationResponse>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reservations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['completeReservationRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 예약을 완료합니다.
     * 예약 완료
     */
    async completeReservation(requestParameters: CompleteReservationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseCompleteReservationResponse> {
        const response = await this.completeReservationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 비회원의 TOPAS 사용자 세션을 생성합니다.
     * 비회원 세션 생성
     */
    async createGuestSessionRaw(requestParameters: CreateGuestSessionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseResult>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sessions/guests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['createGuestSessionRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 비회원의 TOPAS 사용자 세션을 생성합니다.
     * 비회원 세션 생성
     */
    async createGuestSession(requestParameters: CreateGuestSessionOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseResult> {
        const response = await this.createGuestSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 인증코드를 검증한 후 비회원의 TOPAS 사용자 세션을 생성합니다.
     * 인증코드 검증 후 비회원 세션 생성
     */
    async createGuestSessionWithAuthCodeRaw(requestParameters: CreateGuestSessionWithAuthCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseResult>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sessions/guests/auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['createGuestSessionWithAuthCodeRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 인증코드를 검증한 후 비회원의 TOPAS 사용자 세션을 생성합니다.
     * 인증코드 검증 후 비회원 세션 생성
     */
    async createGuestSessionWithAuthCode(requestParameters: CreateGuestSessionWithAuthCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseResult> {
        const response = await this.createGuestSessionWithAuthCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 쏘카 회원의 TOPAS 사용자 세션을 생성합니다.
     * 회원 세션 생성
     */
    async createMemberSessionRaw(requestParameters: CreateMemberSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseCreateSessionResponse>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sessions/members`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 쏘카 회원의 TOPAS 사용자 세션을 생성합니다.
     * 회원 세션 생성
     */
    async createMemberSession(requestParameters: CreateMemberSessionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseCreateSessionResponse> {
        const response = await this.createMemberSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 결제를 진행합니다.
     * 결제 진행
     */
    async executeCardPaymentRaw(requestParameters: ExecuteCardPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListPaymentDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/payments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['cardPaymentRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 결제를 진행합니다.
     * 결제 진행
     */
    async executeCardPayment(requestParameters: ExecuteCardPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListPaymentDto> {
        const response = await this.executeCardPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 네이버 페이 결제를 진행합니다.
     * 네이버 페이 결제 진행
     */
    async executeNaverPayRaw(requestParameters: ExecuteNaverPayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListNaverPayResponseDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/payments/naver-pay`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['naverPayRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 네이버 페이 결제를 진행합니다.
     * 네이버 페이 결제 진행
     */
    async executeNaverPay(requestParameters: ExecuteNaverPayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListNaverPayResponseDto> {
        const response = await this.executeNaverPayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 비회원 예약을 조회합니다.
     * 비회원 예약 조회
     */
    async findGuestReservationRaw(requestParameters: FindGuestReservationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListReservationDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reservations/guest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['findGuestReservationRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 비회원 예약을 조회합니다.
     * 비회원 예약 조회
     */
    async findGuestReservation(requestParameters: FindGuestReservationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListReservationDto> {
        const response = await this.findGuestReservationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 항공사 운임규정을 조회합니다.
     * 항공사 운임규정(예약 후) 조회
     */
    async getAirFareRulesRaw(requestParameters: GetAirFareRulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListReservationAirFareRuleDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reservations/{pnrNo}/air-fare-rules`.replace(`{${"pnrNo"}}`, encodeURIComponent(String(requestParameters['pnrNo']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 항공사 운임규정을 조회합니다.
     * 항공사 운임규정(예약 후) 조회
     */
    async getAirFareRules(requestParameters: GetAirFareRulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListReservationAirFareRuleDto> {
        const response = await this.getAirFareRulesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 항공요금 규정을 조회합니다.
     * 항공요금 규정 조회
     */
    async getAirFareRules1Raw(requestParameters: GetAirFareRules1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseAirFareRulesDto>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/info/air-fare-rules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 항공요금 규정을 조회합니다.
     * 항공요금 규정 조회
     */
    async getAirFareRules1(requestParameters: GetAirFareRules1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseAirFareRulesDto> {
        const response = await this.getAirFareRules1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 항공사 별 탑승객 할인 정보를 조회합니다.
     * 항공사 별 탑승객 할인 정보 조회
     */
    async getAirPaxDiscountsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseAirPaxDiscountsDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reservations/air-pax-discounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 항공사 별 탑승객 할인 정보를 조회합니다.
     * 항공사 별 탑승객 할인 정보 조회
     */
    async getAirPaxDiscounts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseAirPaxDiscountsDto> {
        const response = await this.getAirPaxDiscountsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 국내선 공항 정보를 조회합니다.
     * 국내선 공항 정보 조회
     */
    async getAreasRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListAreaDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/info/areas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 국내선 공항 정보를 조회합니다.
     * 국내선 공항 정보 조회
     */
    async getAreas(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListAreaDto> {
        const response = await this.getAreasRaw(initOverrides);
        return await response.value();
    }

    /**
     * 게시판 상세 정보를 조회합니다.
     * 게시판 상세 조회
     */
    async getBoardDetailRaw(requestParameters: GetBoardDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListBoardDetailDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/boards/{seqNo}`.replace(`{${"seqNo"}}`, encodeURIComponent(String(requestParameters['seqNo']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 게시판 상세 정보를 조회합니다.
     * 게시판 상세 조회
     */
    async getBoardDetail(requestParameters: GetBoardDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListBoardDetailDto> {
        const response = await this.getBoardDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 게시판 목록을 조회합니다.
     * 게시판 목록 조회
     */
    async getBoardsRaw(requestParameters: GetBoardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseBoardsDto>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/boards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 게시판 목록을 조회합니다.
     * 게시판 목록 조회
     */
    async getBoards(requestParameters: GetBoardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseBoardsDto> {
        const response = await this.getBoardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 상담 리스트를 조회합니다.
     * 상담 리스트 조회
     */
    async getCounselsRaw(requestParameters: GetCounselsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListCounselDto>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reservations/counsels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 상담 리스트를 조회합니다.
     * 상담 리스트 조회
     */
    async getCounsels(requestParameters: GetCounselsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListCounselDto> {
        const response = await this.getCounselsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 국내선 스케줄 정보를 조회합니다.
     * 국내선 스케줄 조회
     */
    async getDomesticSchedulesRaw(requestParameters: GetDomesticSchedulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDomesticScheduleGetResponseDto>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 국내선 스케줄 정보를 조회합니다.
     * 국내선 스케줄 조회
     */
    async getDomesticSchedules(requestParameters: GetDomesticSchedulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDomesticScheduleGetResponseDto> {
        const response = await this.getDomesticSchedulesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 기초코드를 조회합니다.(BANNER_LOCATION: 배너 위치, CABIN_CLASS: 좌석 등급, ITINEARY_TYPE: 여정 유형, PNR_SEAT_STATUS: 좌석 상태, RESERVATION_STATUS_CODE: 예약 상태 코드, CARD_TYPE: 카드 종류, CARD_OWNER: 카드 소유자)
     * 기초코드 조회
     */
    async getFundamentalCodesRaw(requestParameters: GetFundamentalCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListFundamentalCodeDto>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/info/fundamental-codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 기초코드를 조회합니다.(BANNER_LOCATION: 배너 위치, CABIN_CLASS: 좌석 등급, ITINEARY_TYPE: 여정 유형, PNR_SEAT_STATUS: 좌석 상태, RESERVATION_STATUS_CODE: 예약 상태 코드, CARD_TYPE: 카드 종류, CARD_OWNER: 카드 소유자)
     * 기초코드 조회
     */
    async getFundamentalCodes(requestParameters: GetFundamentalCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListFundamentalCodeDto> {
        const response = await this.getFundamentalCodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 일반규정 / 결제규정 / 환불규정 정보를 조회합니다.
     * 일반규정 / 결제규정 / 환불규정 정보 조회
     */
    async getGeneralRulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListGeneralRuleDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/info/general-rules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 일반규정 / 결제규정 / 환불규정 정보를 조회합니다.
     * 일반규정 / 결제규정 / 환불규정 정보 조회
     */
    async getGeneralRules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListGeneralRuleDto> {
        const response = await this.getGeneralRulesRaw(initOverrides);
        return await response.value();
    }

    /**
     *              안내 문구를 조회합니다.             AIRFARE: 예약상세페이지-항공요금 문구관리,             PASSENGER_INFO: 예약상세페이지-탑승자정보 문구관리,             PAYMENT_REQUEST: 결제요청 안내문구 관리,             PAYMENT_COMPLETE: 결제완료 안내문구 관리,             TICKET_ISSUED_INTL: 발권완료 안내문구(국제),             TICKET_ISSUED_DOM: 발권완료 안내문구 관리(국내),             PRE_PAYMENT_AGREEMENT_INTL: 결제 전 동의사항(국제선),             REFUND_INFO: 마이페이지 환불안내 문구,             SPECIAL_MEAL_REQUEST_KE: KE 특별기내식 신청 안내문구,             PRE_PAYMENT_AGREEMENT_DOM: 결제 전 동의사항(국내선),             PRE_RESERVATION_CHECK: 예약 전 확인 사항 문구         
     * 안내 문구 조회
     */
    async getGuideMessagesRaw(requestParameters: GetGuideMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListGuideMessageDto>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/info/guide-messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     *              안내 문구를 조회합니다.             AIRFARE: 예약상세페이지-항공요금 문구관리,             PASSENGER_INFO: 예약상세페이지-탑승자정보 문구관리,             PAYMENT_REQUEST: 결제요청 안내문구 관리,             PAYMENT_COMPLETE: 결제완료 안내문구 관리,             TICKET_ISSUED_INTL: 발권완료 안내문구(국제),             TICKET_ISSUED_DOM: 발권완료 안내문구 관리(국내),             PRE_PAYMENT_AGREEMENT_INTL: 결제 전 동의사항(국제선),             REFUND_INFO: 마이페이지 환불안내 문구,             SPECIAL_MEAL_REQUEST_KE: KE 특별기내식 신청 안내문구,             PRE_PAYMENT_AGREEMENT_DOM: 결제 전 동의사항(국내선),             PRE_RESERVATION_CHECK: 예약 전 확인 사항 문구         
     * 안내 문구 조회
     */
    async getGuideMessages(requestParameters: GetGuideMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListGuideMessageDto> {
        const response = await this.getGuideMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 공휴일 정보를 조회합니다. 
     * 공휴일 정보 조회
     */
    async getHolidaysRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseHolidaysGetResponseDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/info/holidays`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 공휴일 정보를 조회합니다. 
     * 공휴일 정보 조회
     */
    async getHolidays(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseHolidaysGetResponseDto> {
        const response = await this.getHolidaysRaw(initOverrides);
        return await response.value();
    }

    /**
     * 인보이스를 조회합니다.
     * 인보이스 조회
     */
    async getInvoiceRaw(requestParameters: GetInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInvoiceDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/payments/invoices/{pnrNo}`.replace(`{${"pnrNo"}}`, encodeURIComponent(String(requestParameters['pnrNo']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 인보이스를 조회합니다.
     * 인보이스 조회
     */
    async getInvoice(requestParameters: GetInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInvoiceDto> {
        const response = await this.getInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 회원 예약을 조회합니다.
     * 회원 예약 조회
     */
    async getMemberReservationsRaw(requestParameters: GetMemberReservationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseGetMemberReservationsResponse>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reservations/member`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 회원 예약을 조회합니다.
     * 회원 예약 조회
     */
    async getMemberReservations(requestParameters: GetMemberReservationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseGetMemberReservationsResponse> {
        const response = await this.getMemberReservationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 국가 정보를 조회합니다.
     * 국가 정보 조회
     */
    async getNationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListNationDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/info/nations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 국가 정보를 조회합니다.
     * 국가 정보 조회
     */
    async getNations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListNationDto> {
        const response = await this.getNationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 편도 예약 상세를 조회합니다.
     * 편도 예약 상세 조회
     */
    async getOneWayReservationDetailRaw(requestParameters: GetOneWayReservationDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseOneWayReservationDetailDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reservations/details/{pnrNo}`.replace(`{${"pnrNo"}}`, encodeURIComponent(String(requestParameters['pnrNo']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 편도 예약 상세를 조회합니다.
     * 편도 예약 상세 조회
     */
    async getOneWayReservationDetail(requestParameters: GetOneWayReservationDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseOneWayReservationDetailDto> {
        const response = await this.getOneWayReservationDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 결제 정보를 조회합니다.
     * 결제 정보 조회
     */
    async getPaymentDetailsRaw(requestParameters: GetPaymentDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListPaymentDetailDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/payments/{pnrNo}`.replace(`{${"pnrNo"}}`, encodeURIComponent(String(requestParameters['pnrNo']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 결제 정보를 조회합니다.
     * 결제 정보 조회
     */
    async getPaymentDetails(requestParameters: GetPaymentDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListPaymentDetailDto> {
        const response = await this.getPaymentDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 등록된 항공편을 조회합니다.
     * 등록된 항공편 조회
     */
    async getRegisteredFlightRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseRegisteredFlightDto>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/schedules/registered-flight`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 등록된 항공편을 조회합니다.
     * 등록된 항공편 조회
     */
    async getRegisteredFlight(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseRegisteredFlightDto> {
        const response = await this.getRegisteredFlightRaw(initOverrides);
        return await response.value();
    }

    /**
     * 왕복 예약 상세를 조회합니다.
     * 왕복 예약 상세 조회
     */
    async getRoundTripReservationDetailRaw(requestParameters: GetRoundTripReservationDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListRoundTripReservationDetailDto>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reservations/details/round-trip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 왕복 예약 상세를 조회합니다.
     * 왕복 예약 상세 조회
     */
    async getRoundTripReservationDetail(requestParameters: GetRoundTripReservationDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListRoundTripReservationDetailDto> {
        const response = await this.getRoundTripReservationDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 네이버 랜딩페이지에서 스케줄을 조회합니다.
     * 네이버 랜딩페이지에서 스케줄 조회
     */
    async getScheduleFromNaverLandingPageRaw(requestParameters: GetScheduleFromNaverLandingPageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseString>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/schedules/naver`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 네이버 랜딩페이지에서 스케줄을 조회합니다.
     * 네이버 랜딩페이지에서 스케줄 조회
     */
    async getScheduleFromNaverLandingPage(requestParameters: GetScheduleFromNaverLandingPageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseString> {
        const response = await this.getScheduleFromNaverLandingPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 현재 세션을 무효화합니다.
     * 세션 무효화
     */
    async invalidateSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseUnit>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sessions/invalidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 현재 세션을 무효화합니다.
     * 세션 무효화
     */
    async invalidateSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseUnit> {
        const response = await this.invalidateSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     * 상담을 등록합니다.
     * 상담 등록
     */
    async registerCounselRaw(requestParameters: RegisterCounselOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseRegisterCounselResult>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reservations/counsels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['registerCounselRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 상담을 등록합니다.
     * 상담 등록
     */
    async registerCounsel(requestParameters: RegisterCounselOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseRegisterCounselResult> {
        const response = await this.registerCounselRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 선택한 항공편을 세션에 등록합니다.
     * 선택한 항공편 세션에 등록
     */
    async registerSelectedFlightToSessionRaw(requestParameters: RegisterSelectedFlightToSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseString>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/schedules/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['registerSelectedFlightRequestDto'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 선택한 항공편을 세션에 등록합니다.
     * 선택한 항공편 세션에 등록
     */
    async registerSelectedFlightToSession(requestParameters: RegisterSelectedFlightToSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseString> {
        const response = await this.registerSelectedFlightToSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 인보이스 메일을 발송합니다.
     * 인보이스 메일 발송
     */
    async sendInvoiceEmailRaw(requestParameters: SendInvoiceEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseUnit>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notification/invoices/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['invoiceEmailRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 인보이스 메일을 발송합니다.
     * 인보이스 메일 발송
     */
    async sendInvoiceEmail(requestParameters: SendInvoiceEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseUnit> {
        const response = await this.sendInvoiceEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 예약자 연락처를 변경합니다.
     * 예약자 연락처 변경
     */
    async updateContactRaw(requestParameters: UpdateContactOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseResult>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reservations/contacts`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['updateContactRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 예약자 연락처를 변경합니다.
     * 예약자 연락처 변경
     */
    async updateContact(requestParameters: UpdateContactOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseResult> {
        const response = await this.updateContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetBoardsBoardType = {
    NOTICE: 'NOTICE',
    FAQ: 'FAQ'
} as const;
export type GetBoardsBoardType = typeof GetBoardsBoardType[keyof typeof GetBoardsBoardType];
/**
 * @export
 */
export const GetFundamentalCodesMasterCode = {
    BANNER_LOCATION: 'BANNER_LOCATION',
    CABIN_CLASS: 'CABIN_CLASS',
    ITINEARY_TYPE: 'ITINEARY_TYPE',
    PNR_SEAT_STATUS: 'PNR_SEAT_STATUS',
    RESERVATION_STATUS_CODE: 'RESERVATION_STATUS_CODE',
    CARD_TYPE: 'CARD_TYPE',
    CARD_OWNER: 'CARD_OWNER',
    COUNSEL_TYPE: 'COUNSEL_TYPE'
} as const;
export type GetFundamentalCodesMasterCode = typeof GetFundamentalCodesMasterCode[keyof typeof GetFundamentalCodesMasterCode];
/**
 * @export
 */
export const GetGuideMessagesCodes = {
    AIRFARE: 'AIRFARE',
    PASSENGER_INFO: 'PASSENGER_INFO',
    PAYMENT_REQUEST: 'PAYMENT_REQUEST',
    PAYMENT_COMPLETE: 'PAYMENT_COMPLETE',
    TICKET_ISSUED_INTL: 'TICKET_ISSUED_INTL',
    TICKET_ISSUED_DOM: 'TICKET_ISSUED_DOM',
    PRE_PAYMENT_AGREEMENT_INTL: 'PRE_PAYMENT_AGREEMENT_INTL',
    REFUND_INFO: 'REFUND_INFO',
    SPECIAL_MEAL_REQUEST_KE: 'SPECIAL_MEAL_REQUEST_KE',
    PRE_PAYMENT_AGREEMENT_DOM: 'PRE_PAYMENT_AGREEMENT_DOM',
    PRE_RESERVATION_CHECK: 'PRE_RESERVATION_CHECK'
} as const;
export type GetGuideMessagesCodes = typeof GetGuideMessagesCodes[keyof typeof GetGuideMessagesCodes];
/**
 * @export
 */
export const GetMemberReservationsRsvStatus = {
    UNKNOWN: 'UNKNOWN',
    AUTO_CANCELED: 'AUTO_CANCELED',
    WAITING: 'WAITING',
    COMPLETED: 'COMPLETED',
    PAYMENT_REQUESTED: 'PAYMENT_REQUESTED',
    TICKET_ISSUED: 'TICKET_ISSUED',
    CANCELED: 'CANCELED',
    UNPAID_TICKET_ISSUED: 'UNPAID_TICKET_ISSUED'
} as const;
export type GetMemberReservationsRsvStatus = typeof GetMemberReservationsRsvStatus[keyof typeof GetMemberReservationsRsvStatus];
