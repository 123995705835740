import { DefaultHeader } from '@src/components/common'
import {
  HydrationBoundary,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {
  getSession,
  SessionProvider,
  signOut,
  useSession,
} from 'next-auth/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { type AppProps } from 'next/app'
import { useState } from 'react'
import { RecoilRoot } from 'recoil'
import '../styles/global.css'
import { DefaultLayout, PageNameProvider } from '@src/components/common/layout'
import ReactModal from 'react-modal'
import { SnackbarProvider } from '@src/components/common/FlightSnackbar'
import { ResponseError } from '@src/api'
import { useRouter } from 'next/router'
import { createSessionOnce } from '@src/utils/createSessionOnce'

// 스크린 리더기에서 뒷 내용이 노출되는 현상을 방지
ReactModal.setAppElement('#__next')

function InnerApp({
  Component,
  pageProps,
}: Pick<AppProps, 'Component' | 'pageProps'>) {
  const { update: updateSession } = useSession()
  const router = useRouter()

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: { queries: { retry: false } },
        queryCache: new QueryCache({
          onError: async (error, query) => {
            if (
              error instanceof ResponseError &&
              error.response.status === 403
            ) {
              const session = await getSession()
              const accessToken = session?.user?.accessToken
              const refreshToken = session?.user?.refreshToken
              const isLoggedIn = session?.user?.isLoggedIn

              /** 비회원 세션 만료 처리 */
              if (!accessToken || !refreshToken || !isLoggedIn) {
                if (router.pathname === '/reservation') {
                  return router.replace('/?RESERVATION_SESSION=ERROR')
                }

                const { isMember } = router.query
                /** 비회원 상태에서 회원이 예약한 항공권 상세 접근 시 */
                if (
                  router.pathname === '/reservations/[pnrNo]' &&
                  isMember === 'true'
                ) {
                  router.replace({
                    pathname: '/',
                    query: { OPEN_LOGIN_BOTTOM_SHEET: 'true' },
                  })

                  return
                }

                if (
                  router.pathname === '/reservations/[pnrNo]' ||
                  router.pathname === '/reservations-guest'
                ) {
                  return router.replace('/reservations-guest/check-info')
                }

                return router.replace('/?GUEST_SESSION=ERROR')
              }

              /** 세션 재생성 */
              try {
                const createSessionResponse = await createSessionOnce({
                  accessToken,
                  refreshToken,
                })

                if (createSessionResponse?.data) {
                  const data = createSessionResponse.data

                  await updateSession({
                    user: {
                      name: data.member.name,
                      email: data.member.email,
                      phone: data.member.phone,
                      accessToken: data.accessToken,
                      refreshToken: data.refreshToken,
                    },
                  })
                }
              } catch (error) {
                /** 회원 세션 재생성 실패 처리 */
                await signOut({ redirect: false, callbackUrl: '/logout' })
                router.replace('/?MEMBER_SESSION=ERROR')

                return
              }

              if (router.pathname === '/reservation') {
                /** 예약 화면에서 세션 만료 처리 */
                router.replace('/?RESERVATION_SESSION=ERROR')
              } else {
                queryClient.invalidateQueries(query)
              }
            }
            if (
              error instanceof ResponseError &&
              error.response.status === 500
            ) {
              /** 회원 로그인 상태, (인보이스)메일 > 비회원 예약 내역 보기 이동 시 발생하는 500 error 처리  */
              if (router.pathname === '/reservations/[pnrNo]') {
                router.replace('/')
              }
            }
          },
        }),
        mutationCache: new MutationCache({
          onError: async (error, variables, context, mutation) => {
            if (
              error instanceof ResponseError &&
              error.response.status === 403
            ) {
              const session = await getSession()
              const accessToken = session?.user?.accessToken
              const refreshToken = session?.user?.refreshToken
              const isLoggedIn = session?.user?.isLoggedIn

              /** 비회원 세션 만료 처리 */
              if (!accessToken || !refreshToken || !isLoggedIn) {
                if (router.pathname === '/reservation') {
                  return router.replace('/?RESERVATION_SESSION=ERROR')
                }

                if (
                  router.pathname === '/reservations/[pnrNo]' ||
                  router.pathname === '/reservations-guest'
                ) {
                  router.replace('/reservations-guest/check-info')
                } else {
                  router.replace('/?GUEST_SESSION=ERROR')
                }

                return
              }

              try {
                const createSessionResponse = await createSessionOnce({
                  accessToken,
                  refreshToken,
                })

                if (createSessionResponse?.data) {
                  const data = createSessionResponse.data

                  await updateSession({
                    user: {
                      name: data.member.name,
                      email: data.member.email,
                      phone: data.member.phone,
                      accessToken: data.accessToken,
                      refreshToken: data.refreshToken,
                    },
                  })
                }
              } catch (error) {
                /** 회원 세션 재생성 실패 처리 */
                await signOut({ redirect: false, callbackUrl: '/logout' })
                router.replace('/?MEMBER_SESSION=ERROR')

                return
              }

              if (router.pathname === '/reservation') {
                /** 예약 화면에서 세션 만료 처리 */
                router.replace('/?RESERVATION_SESSION=ERROR')
              } else {
                const { mutationFn, onSuccess, onError, onSettled } =
                  mutation.options

                if (mutationFn) {
                  try {
                    const data = await mutationFn(variables)

                    onSuccess && onSuccess(data, variables, context)
                    onSettled && onSettled(data, error, variables, context)
                  } catch (error) {
                    onError && onError(error, variables, context)
                    onSettled && onSettled(null, error, variables, context)
                  }
                }
              }
            }
          },
        }),
      })
  )

  const getLayout =
    Component.getLayout || ((page) => <DefaultLayout>{page}</DefaultLayout>)

  // TODO : 추후에 RUM initialize 필요합니다
  // TODO: 이 부분에 넣으면 일부 트래킹이 유실됩니다.
  // useEffect(() => {
  //   initRum({
  //     config: {
  //       applicationId: '입력 필요',
  //       clientToken: '입력 필요',
  //       service: 'socar-flight',
  //     },
  //     enabled: false,
  //   })
  // }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={pageProps.dehydratedState}>
        <RecoilRoot>
          <DefaultHeader />
          <PageNameProvider>
            <SnackbarProvider>
              {getLayout(<Component {...pageProps} />)}
            </SnackbarProvider>
          </PageNameProvider>
          <ReactQueryDevtools
            initialIsOpen={process.env.NODE_ENV === 'development'}
          />
        </RecoilRoot>
      </HydrationBoundary>
    </QueryClientProvider>
  )
}

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <SessionProvider session={pageProps.session}>
      <InnerApp Component={Component} pageProps={pageProps} />
    </SessionProvider>
  )
}

export default MyApp
