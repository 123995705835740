/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResponseListBannerDto,
} from '../models/index';

export interface GetBannersRequest  {
  position?: GetBannersPosition;
}


/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * 배너 목록을 조회합니다.
     * 배너 목록 조회
     */
    async getBannersRaw(requestParameters: GetBannersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseListBannerDto>> {

        const queryParameters: any = { ...requestParameters };

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("authCode", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/banners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 배너 목록을 조회합니다.
     * 배너 목록 조회
     */
    async getBanners(requestParameters: GetBannersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseListBannerDto> {
        const response = await this.getBannersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetBannersPosition = {
    TOP: 'TOP',
    BOTTOM: 'BOTTOM',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT'
} as const;
export type GetBannersPosition = typeof GetBannersPosition[keyof typeof GetBannersPosition];
