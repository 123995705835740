import { isClientSide } from '@socar-inc/utils'
import { AtomEffect } from 'recoil'

export const storageEffect =
  <T>(key: string, storageType: 'session' | 'local'): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    if (!isClientSide) return
    const storage = storageType === 'session' ? sessionStorage : localStorage
    const storageValue = storage.getItem(key)

    if (storageValue !== null) {
      let savedValue = JSON.parse(storageValue)

      // key 값이 Date가 포함되어 있으면 Date 객체로 변환
      if (key.includes('Date') && savedValue) {
        savedValue = new Date(savedValue)
      } else if (Array.isArray(savedValue)) {
        // 배열 내부 객체에 Date가 포함되어 있으면 Date 객체로 변환
        savedValue = savedValue.map((item) => {
          if (item && typeof item === 'object') {
            const newItem = { ...item }
            Object.keys(newItem).forEach((k) => {
              if (k.toLowerCase().includes('date') && newItem[k]) {
                newItem[k] = new Date(newItem[k])
              }
            })
            return newItem
          }
          return item
        })
      }

      setSelf(savedValue)
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? storage.removeItem(key)
        : storage.setItem(key, JSON.stringify(newValue))
    })
  }
