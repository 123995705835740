import { atom } from 'recoil'
import { IS_RIGHT_UNIT_VISIBLE } from '../constants/common/atomsKey'

/**
 * @description 액션바에 오른쪽 유닛 노출 여부
 */
export const isRightUnitVisibleState = atom<boolean>({
  key: IS_RIGHT_UNIT_VISIBLE,
  default: false,
})
