// common.ts
export const IS_SEARCH_MODAL_OPEN = 'isSearchModalOpenState'
export const SEARCH_TYPE = 'searchTypeState'
export const IS_BASIC_FLOW = 'isBasicFlowState'
export const ITINERARY_TYPE = 'itineraryTypeState'

// date.ts
export const TRAVEL_START_DATE = 'travelStartDateState'
export const TRAVEL_END_DATE = 'travelEndDateState'

// personnel.ts
export const ADULT_COUNT = 'adultCountState'
export const CHILD_COUNT = 'childCountState'
export const INFANT_COUNT = 'infantCountState'
export const SEAT_TYPES = 'seatTypesState'

// travelDestination.ts
export const DEPARTURE = 'departureState'
export const ARRIVAL = 'arrivalState'

// recentSearch.ts
export const RECENT_SEARCHES = 'recentSearchesState'
